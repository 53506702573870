import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from './Header';
import MainFeaturedPost from './MainFeaturedPost';
import FeaturedPost from './FeaturedPost';
import Main from './Main';
import Sidebar from './Sidebar';
import Footer from './Footer';
import { Helmet } from "react-helmet";

/*
 * Importing the posts from the .md files as shown below (from the MUI blog
 * template) does not work with create-react-app. Tried a couple of different 
 * solutions and the one that worked is from here:
 * https://github.com/facebook/create-react-app/issues/3722
 */
//import post1 from './blog-post.1.md';
//import post2 from './blog-post.2.md';
//import post3 from './blog-post.3.md';
import raw from 'raw.macro';

const previewPosts = [
  {post: raw('./how-supplemental-income-taxed.md'), to: '/taxes/how-bonus-taxed-netherlands'},
  {post: raw('./efteling.md'), to: '/visiting-efteling-winter-children'},
  {post: raw('./revolut.md'), to: '/expattools/cheapest-money-transfer-us-netherlands'},
];

console.log("test reload1");

export const sections = [
  { title: 'Home', url: '/' },
  { title: 'Taxes', url: '/taxes' },
  { title: 'Housing', url: '/housing' },
  { title: 'Expat Tools', url: '/expattools' },
  { title: 'Store Finder', url: '/storemap' },
  { title: 'My Recommendations', url: '/recommendations' },
  { title: 'Archives', url: '/archives' },
  { title: 'Contact', url: '/contact' },
];

const imageAttribution = [
  {
    bylink: "https://unsplash.com/@peterctid?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText",
    by: "Peter Hall",
    from: "Unsplash",
    img_name: 'kinderdijk_windmills.jpg',
  },
  {
    bylink: "https://unsplash.com/@gxjansen?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText",
    by: "Guido Jansen",
    from: "Unsplash",
    img_name: 'surprised_cow.jpg',
  },
  {
    bylink: "https://unsplash.com/@matheo_jbt?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText",
    by: "Matheo JBT",
    from: "Unsplash",
    img_name: 'ams_house.jpg',
  }
]

const surpriseMe = [
  'https://youtu.be/navB1r96_do?t=40',
  'https://what-if.xkcd.com/53/',
  'https://archive.org/details/donaldducknl',
  'https://youtu.be/lP-G-inkkDg',
]

const tipOfTheDay = [
  {tip:'All over the Netherlands, the public warning sirens are tested at exactly 12.00 noon on the first Monday of every month. The siren sounds for 1 minute and 26 seconds without interruption.', link:'', linkText:''},
  {tip:'Amsterdam is phasing out gas. If you are installing a new kitchen, or replacing a stove, consider getting an induction stove.', link:'', linkText:''},
  {tip:'The Dutch Education Group on Facebook is an excellent resource for all things schooling related in the Netherlands.', link:'', linkText:''},
  {tip:'The Hogenood app shows locations of public toilets', link:'https://en.hogenood.nl', linkText:'Get the app here'},
  {tip:'Marktplaats is the Dutch Craigslist', link:'', linkText:''},
  {tip:'The desktop version of Duolingo has no advertisements and unlimited hearts even if you do not have Duolingo Plus.', link:'', linkText:''},
  {tip:'Buienradar is a must-have app to help you to avoid getting caught unawares by Dutch weather.', link:'', linkText:''},
  {tip:'If you are driving in to Amsterdam, intending to go to the city center, the cheapest way is: park at a P+R garage (e.g. at the RAI), take public transportion to the center (e.g. Metro line 52 from the RAI to Rokin takes 5 minutes), and then pay €1 for 24 hours of parking.', link:'', linkText:''},
  {tip:'If you have the 30% ruling, you (and your spouse) can swap your US driving license for a Dutch one without having to take any theory or practical exams.', link:'', linkText:''},
  {tip:'You are allowed to change your insurance policy and/or provider once a year, at the end of the year, before Dec 31st.', link:'', linkText:''},
  {tip:'In the rental market unfurnished can mean no floor or lighting fixtures. It is common to install your own flooring in a rental and to take it out when you leave.', link:'', linkText:''},
  {tip:'Old Donald Duck comics are available online in Dutch for free', link:'https://archive.org/details/donaldducknl', linkText:'Go to the archive'},
  {tip:'You can request a Nee-Nee sticker from your gemeente for your mailbox if you do not want to receive printed advertising material or free door-to-door newspapers.', link:'', linkText:''}, 
  {tip:'In some cities in the Netherlands if you own a dog you have to pay an annual dog tax. Google hondenbelasting and your city name for more information.', link:'', linkText:''},
] 

let mainFeaturedPost = {
  title: 'Tip Of The Day',
  description: "",
  image: 'kinderdijk_windmills.jpg',
  imageText: 'Kinderdijk Windmills',
  linkText: '',
  linkTo: '',
};

let featuredPosts = [
  {
    title: 'Compute the Cost of Buying a Home',
    description:
      'Use this calculator to compute the cost of buying a home in the Netherlands',
    image: 'ams_house.jpg',
    imageLabel: 'Amsterdam House',
    to: '/housing/home-buying-costs',
    linkText: 'Click here to calculate...',
    extlink: false,
  },
  {
    title: 'Surprise Me!',
    description:
      'Click here to visit a location on the internet that is related to your new home - The Netherlands',
    image: 'surprised_cow.jpg',
    imageLabel: 'Surprised cow',
    to: '',
    extlink: true,
  },
];

const sidebar = {
  title: 'About the Author',
  description:
    'Anureita Rao is Indian by birth, a US citizen, and resides in the Netherlands with her husband, two daughters, and a dog who is afflicted with extreme fuzziness. In her previous life she worked as a software developer in Silicon Valley.',
  social: [
    { name: 'Twitter', link: 'https://twitter.com/theabnl', icon: TwitterIcon },
    { name: 'Instagram', link: 'https://www.instagram.com/theamericanburgerguide/', icon: InstagramIcon },
  ],
};

const theme = createTheme();

export default function Blog() {

  var now = new Date();
  var fullDaysSinceEpoch = Math.floor(now/8.64e7);

  let tip = fullDaysSinceEpoch % tipOfTheDay.length;
  let newTip = tipOfTheDay[tip];
  mainFeaturedPost.description = newTip.tip;
  if (newTip.link) {
    mainFeaturedPost.linkTo = newTip.link;
    mainFeaturedPost.linkText = newTip.linkText;
  }

  let surprise = fullDaysSinceEpoch % surpriseMe.length;
  let todaySurprise = surpriseMe[surprise];
  featuredPosts[1].to = todaySurprise;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg">
        <Helmet>
          <title>The American Burger</title>
          <meta name="description" content="The complete guide for American expats living in the Netherlands" />
        </Helmet>
        <Header title="The American Burger's Guide to The Netherlands" sections={sections} />
        <main>
          <MainFeaturedPost post={mainFeaturedPost} />
          <Grid container spacing={4}>
            {featuredPosts.map((post) => (
              <FeaturedPost key={post.title} post={post} />
            ))}
          </Grid>
          <Grid container spacing={5} sx={{ mt: 3 }}>
            <Main title="Latest Posts" previews={previewPosts} changehead={false} noheadings={true} fullwidth={false}/>
            <Sidebar
              title={sidebar.title}
              description={sidebar.description}
              archives={sidebar.archives}
              social={sidebar.social}
            />
          </Grid>
        </main>
      </Container>
      <Footer
        imageattr={imageAttribution}
      />
    </ThemeProvider>
  );
}