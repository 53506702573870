import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from './Header';
import Footer from './Footer';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import { sections } from './Blog';
import { Helmet } from "react-helmet";
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Switch from '@mui/material/Switch'
import { orange } from '@mui/material/colors';
import { styled } from '@mui/system';
import { alpha } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';

const theme = createTheme();

/*
 * Store the results of all intermediate calculations so that we can display 
 * them if the user flips the 'show me the math' switch.
 */
const calcs = {
  lump_sum: 0,
  annual_canon: 0,
  year: 0,
  inflation: 0,
  return_rate: 0,
  ac_year: 0,
  ac_after_deduction: 0,
  nest_egg: 0,
  fail_wr: 0,
  fail_year: 0,
  final_nest_egg: 0,
  pay_lump_sum: 0,
}

function resetCalcs() {
  Object.keys(calcs).forEach(k => {
      calcs[k] = 0;   
  });
}

/*
 * When the user flips the switch, show them the erfpacht math
 */
function ShowMath(props) {  
  if (props.show) {
    return (
      <TableContainer>
        <Table aria-label="simple table">
          <TableBody> 
            <TableRow>
              <TableCell>Lump Sum</TableCell>
              <TableCell>€{calcs.lump_sum}</TableCell>
          </TableRow>
          <TableRow>
              <TableCell>Annual Canon</TableCell>
              <TableCell>€{calcs.annual_canon}</TableCell>
          </TableRow>
          <TableRow>
              <TableCell>Year in which you switch to a perpetual leasehold</TableCell>
              <TableCell>{calcs.year}</TableCell>
          </TableRow>
          <TableRow>
              <TableCell>Inflation</TableCell>
              <TableCell>{calcs.inflation}%</TableCell>
          </TableRow>
          <TableRow>
              <TableCell>Rate of return</TableCell>
              <TableCell>{calcs.return_rate}%</TableCell>
          </TableRow>
          <TableRow>
              <TableCell>Annual canon after inflation in {calcs.year}</TableCell>
              <TableCell>€{calcs.ac_year.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
              <TableCell>Annual canon in {calcs.year} after factoring in deduction</TableCell>
              <TableCell>€{calcs.ac_after_deduction.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
              <TableCell>Nest egg in {calcs.year} resulting from investing €{calcs.lump_sum} at {calcs.return_rate}%</TableCell>
              <TableCell>€{calcs.nest_egg.toFixed(2)}</TableCell>
          </TableRow>
          {calcs.pay_lump_sum === "Yes" ? 
            <TableRow>
                <TableCell>Year in which nest egg ran out</TableCell>
                <TableCell>{calcs.fail_year}</TableCell>
            </TableRow> : <></>
          }
          {calcs.pay_lump_sum === "Maybe" ?
            <TableRow>
                <TableCell>Nest egg lasted for 100 years, but likely to fail in the future. Withdrawal rate at year 100 is</TableCell>
                <TableCell>{(calcs.fail_wr * 100).toFixed(2)}%</TableCell>
            </TableRow> : <></>
          }
          {calcs.pay_lump_sum === "No" ?
           <TableRow>
                <TableCell>Nest egg remaining in 100 years</TableCell>
                <TableCell>€{calcs.final_nest_egg.toFixed(2)}</TableCell>
            </TableRow> : <></>
          }
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
  return null;
}

// Once we have switched to a perpetual leashold, for every year up
// to year 100 (starting from today), check to see if our nest egg
// runs out if we pay wealth tax and the inflation adjusted canon every
// year, taking tax deductions into account.
function calcSWR(lump_sum, canon, inflation, return_rate, num_years) {
  var egg = Number(lump_sum);
  var prev_egg = 0;
  var y = 0;
  var wr;

  while (y < num_years) {
    prev_egg = egg;
    egg += egg * (return_rate/100);
    // Subtract wealth tax
    egg -= ((egg - prev_egg) * .31);
    if (egg < canon) {
      console.log("Failed");
      wr = canon/egg;
      calcs.fail_wr = wr;
      calcs.fail_year += y;
      return "Yes";
    }
    // Subtract canon paid, taking deduction into account.
    // Assume 37% deductible and 49% marginal rate. 
    egg -= (.82 * canon);
    // Adjust canon by inflation for the next year
    canon += canon * (inflation/100);
    y++;
  }
  wr = canon/egg;
  if (wr > .035) {
    // We didn't run out of our nest egg, but we are heading there.
    calcs.fail_wr = wr;
    return "Maybe";
  }
  calcs.final_nest_egg = egg;
  return "No";
}

// For the num_years until the switch to a perpetual leashold, calculate the
// nest egg growth.
// Assume that every year the nest egg grows by return_rate.
// Also assume that every year wealth tax eats up 31% of the return.
function calcStartingNestEgg(lump_sum, return_rate, num_years) {
  var egg = Number(lump_sum);
  var prev_egg = 0;
  var y = 0;

  while (y < num_years) {
    prev_egg = egg;
    egg += egg * (return_rate/100);
    egg -= ((egg - prev_egg) * .31);
    y++;
  }
  return egg;
}

function calcErfpachtCost(lump_sum, annual_canon, start_year, inflation, return_rate) {

  resetCalcs();
  var current_year = new Date().getFullYear();

  if (start_year < current_year) {
    return "Error";
  }

  calcs.lump_sum = lump_sum;
  calcs.annual_canon = annual_canon;
  calcs.year = start_year;
  calcs.inflation = inflation;
  calcs.return_rate = return_rate;

  var now_year = new Date().getFullYear();
  var num_years = start_year - now_year;
  
  calcs.ac_year = annual_canon * ((1+inflation/100) ** num_years);
  // assume 37% deductible and 49% marginal rate
  calcs.ac_after_deduction = calcs.ac_year * .82;
  calcs.nest_egg = calcStartingNestEgg(lump_sum, return_rate, num_years);
  calcs.fail_year = Number(start_year);
  calcs.pay_lump_sum = calcSWR(calcs.nest_egg, calcs.ac_year, inflation, calcs.return_rate, 100 - num_years);
  return calcs.pay_lump_sum;
}

const OrangeSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: orange[600],
    '&:hover': {
      backgroundColor: alpha(orange[600], theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: orange[600],
  },
}));


export default function ErfpachtCalc() {

  let buy = false;

  const [lump_sum, setLumpSum] = React.useState(0);
  const handleLumpSumChange = (event) => {
    setLumpSum(event.target.value);
  };

  const [annual_canon, setAnnualCanon] = React.useState(0);
  const handleAnnualCanonChange = (event) => {
    setAnnualCanon(event.target.value);
  };

  const [start_year, setStartYear] = React.useState(new Date().getFullYear());
  const handleStartYearChange = (event) => {
    setStartYear(event.target.value);
  };

  const [inflation, setInflation] = React.useState(3);
  const handleInflationChange = (event) => {
    setInflation(event.target.value);
  };

  const [return_rate, setReturnRate] = React.useState(7);
  const handleReturnRateChange = (event) => {
    setReturnRate(event.target.value);
  };

  const [buy_canon, setBuyCanon] = React.useState("");

  const [checked, setChecked] = React.useState(false);

  const switchHandler = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg">
        <Helmet>
          <title>Switching to a Perpetual Leasehold</title>
          <meta name="description" content="A calculator to decide which payment option to choose when switching to a perpetual leasehold" />
        </Helmet>
        <Header title="The American Burger's Guide to Perpetual Leaseholds" sections={sections} />
        <main>
          <Paper
            sx={{
              position: 'relative',
              p: { xs: 2, md: 4 },
              mb: 2,
            }}
          >
            <Grid container spacing={1}>
              <Grid item md={6}>
                <Typography variant="body2" paragraph>
                  If you are considering switching from a continuous (*voortdurende erfpacht*) to a perpetual leasehold (*eeuwigdurende
                  erfpacht*), you need to decide how you are going to pay for the privilege of being a leaseholder in perpetuity. 
                  The Gemeente will make you an offer (sadly not one that you simply cannot refuse), and you will need to 
                  choose a payment option: an <b>annual payment</b> or a <b>lump sum</b>.<br />

                  This is a tough decision, because you don't own a crystal ball. How bad will inflation be in the future? How
                  will your investments perform? How drastically will tax laws change? This calculator provides a framework to make
                  a decision - you make your best guesses about the future, and based on those guesses the calculator tells you
                  which option is mathematically superior.<br /><br />
                  
                  <b>How does this calculator work?</b><br />
                  The calculator is based on the principle of a <a href="https://en.wikipedia.org/wiki/Trinity_study" target="_blank" rel="noreferrer">safe withdrawal rate (SWR)</a>. The
                  concept of a safe withdrawal rate is normally used for retirement planning - how large a nest egg do you need to accumulate so 
                  that you can live off it in retirement. Here we use the concept of a SWR to determine the following:<br />
                  What if instead of paying the Gemeente the lump sum, you were to invest it instead? Would the nest egg thus created 
                  support a withdrawal of the annual canon in perpetuity?<br /><br />

                  For a duration of 100 years the calculator:<br />
                  1. Assumes the lump sum grows by the return rate you enter.<br />
                  2. Assumes that you pay box 3 taxes on the lump sum.<br />
                  3. Assumes the annual canon increases by the inflation rate you enter.<br />
                  4. If it is past the year you entered to switch to the perpetual canon, the annual canon is deducted from the 
                  lump sum.<br /><br />

                  If after a 100 years the lump sum is not depleted and the withdrawal rate is safe, the calculator will advise you
                  not to pay the lump sum to the Gemeente.<br />
                  If after a 100 years the lump sum did not run out, but the withdrawal rate is no longer safe, the calculator will
                  say that you should maybe consider paying the lump sum to the Gemeente.<br />
                  If within the 100 year period the lump sum runs out the calculator will advise you to choose the lump sum option
                  and pay off the Gemeente now.<br /><br />

                  <b>Assumptions</b><br />
                  1. We assume a safe withdrawal rate of 3.5%. The original SWR study recommended 4% but there has been subequent analysis (for
                  example, see the <a href="https://earlyretirementnow.com/2016/12/14/the-ultimate-guide-to-safe-withdrawal-rates-part-2-capital-preservation-vs-capital-depletion/" target="_blank" rel="noreferrer">ERN SWR series</a>) that
                  shows that 3.5% holds up better for longer durations. <br />
                  2. Under current law an annual payment is tax deductible, but a lump sum payment is not. So, when we are determining the 
                  future inflation-adjusted annual canon, we assume that 37% of the annual canon would be tax deductible at a marginal
                  rate of 49%.<br />
                  3. We assume that box 3 taxation eats away at the capital. The new box 3 scheme based on real returns is still only
                  a proposal, so this calculator simply assumes that 31% (the current box 3 tax rate) of the returns every year are taxed. <br /><br />

                  <b>Disclaimers</b><br />
                  This is a simplistic model. While your investments may return of average of say 7% over a period of 100 years, the stock market does not return a 
                  predictable 7% every year. So in real life your invested lump sum will suffer from <a href="https://www.schwab.com/learn/story/timing-matters-understanding-sequence-returns-risk" target="_blank" rel="noreferrer">sequence of returns risk</a> - a few
                  initial bad years of returns may make your annual withdrawals non-sustainable, even if the average returns over a 100 years
                  end up being 7%. This calculator obviously cannot guarantee that real life will mimic the 100-ish lines of code that
                  produce the outcome of the calculator. The goal is to give you a framework within which you can evaluate your payment
                  options and make an informed decision.

               </Typography>
              </Grid>
              <Grid item md={6}>
                <Grid container spacing={1} columns={{ xs: 6, md: 6 }}>
                  <Grid item xs={4} md={4}>
                    <Accordion disableGutters> 
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="lump-sum-explain"
                      >
                        <Typography variant="body2">Enter the lump sum cost of buying out the canon in perpetuity</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography variant="body2">
                          In the offer letter from the Gemeente this will be called something like *Afkoopsom eeuwigdurende erfpacht*
                          in Dutch. Note that a lump sum payment to the Gemeente is not tax-deductible. 
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <TextField
                      id="lump-sum"
                      type="number"
                      value={lump_sum}
                      onChange={handleLumpSumChange}
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">€</InputAdornment>,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Accordion disableGutters>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="annual-canon-explain"
                        >
                          <Typography variant="body2">Enter the annual canon value that you can lock in now</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography variant="body2">
                            In the offer letter from the Gemeente this will be called the *jaarlijkse canon* in Dutch. 
                            If you choose not to make a lump sum payment, this is the annual amount that you will need to pay
                            the city in perpetuity. The canon is based on the land value. You are locking in a value for 
                            perpetuity based on the value of land today. So no matter what happens to the value of the land in the 
                            future, your payment is unaffected. it is worth noting that while the annual canon is fixed with 
                            respect to the value of land, it can still increase based on inflation.<br />
                            If your current continous leasehold is bought out until some year in the future, this annual canon 
                            payment starts after the current period expires.<br />
                            The annual canon payment is tax deductible.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <TextField
                      id="annual-canon"
                      type="number"
                      value={annual_canon}
                      onChange={handleAnnualCanonChange}
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">€</InputAdornment>,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Accordion disableGutters>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="year-explain"
                        >
                          <Typography variant="body2">Enter the calendar year in which you will switch to a perpetual leasehold</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography variant="body2">
                            If your continuous leashold has already been bought out (typically for a period of 50 years), then
                            your first annual payment under the perpetual leasehold will be due once your continuous leasehold
                            expires. 
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <TextField
                      id="year"
                      type="number"
                      value={start_year}
                      onChange={handleStartYearChange}
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item container xs={4} md={4}>
                    <Accordion disableGutters>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="inflation-explain"
                      >
                        <Typography variant="body2">Assumed rate of inflation</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography variant="body2">
                          Inflation - the big unknown! The annual payment that you lock in now will be inflation adjusted
                          to the year in which your payment is due. Enter the rate of inflation that you would like the 
                          calculator to assume.  
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <TextField
                      id="inflation"
                      type="number"
                      value={inflation}
                      onChange={handleInflationChange}
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      }}
                    />
                  </Grid> 
                  <Grid item xs={4} md={4}>
                    <Accordion disableGutters>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="return-rate-explain"
                      >
                        <Typography variant="body2">Assumed nominal rate of return on investments</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography variant="body2">
                          Enter the nominal rate of returns that you would like the calculator to assume. For reference,
                          the <a href="https://www.investopedia.com/ask/answers/042415/what-average-annual-return-sp-500.asp#:~:text=Key%20Takeaways,its%201957%20inception%20through%202021." target="_blank" rel="noreferrer">average annualized return for the S&P 500</a> from
                          1957 to 2021 was 10.67%.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <TextField
                      id="rate-of-return"
                      type="number"
                      value={return_rate}
                      onChange={handleReturnRateChange}
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} container sx={{alignItems: "center", justifyContent: "center"}}>
                    <Button 
                      variant="contained"
                      onClick={() => {
                        setChecked(false);
                        buy = calcErfpachtCost(lump_sum, annual_canon, start_year, inflation, return_rate);
                        if (buy === "Error") {
                          alert('The switch to perpetual leasehold must happen in the current year or later!');
                          setStartYear(new Date().getFullYear());
                        } else {
                          setBuyCanon(buy);
                        }
                      }}
                    >
                      Should I make a lump sum payment?
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={12} container sx={{alignItems: "center", justifyContent: "center"}}>
                    <Typography><b>{buy_canon}</b></Typography>
                  </Grid>
                  <Grid item xs={12} md={12} container sx={{alignItems: "center", justifyContent: "center"}}>
                    <FormControlLabel
                      control={<OrangeSwitch checked={checked} onChange={switchHandler}/>}
                      label="Show me the math"
                    />
                  </Grid> 
                </Grid>
                <Grid container>
            <Grid item xs={12}>
              <ShowMath show={checked}/> 
          </Grid> 
          </Grid>
          </Grid>
          </Grid>
          </Paper>
        </main>
      </Container>
      <Footer/>
    </ThemeProvider>
  );
}