import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from './Header';
import MainFeaturedPost from './MainFeaturedPost';
import FeaturedPost from './FeaturedPost';
import Footer from './Footer';
import { sections } from './Blog';
import { Helmet } from "react-helmet";

const imageAttribution = [
  {
    bylink: "https://unsplash.com/@towfiqu999999?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText",
    by: "Towfiqu Barbhuiya",
    from: "Unsplash",
    img_name: 'pile_of_coins.jpg',
  },
  {
    bylink: "https://unsplash.com/@sasun1990?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText",
    by: "Sasun Bughdaryan",
    from: "Unsplash",
    img_name: 'calculator.jpg',
  },
  {
    bylink: "https://unsplash.com/@omidarmin?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText",
    by: "Omid Armin",
    from: "Unsplash",
    img_name: 'box3-cash.jpg',
  },
  {
    bylink: "https://unsplash.com/@kellysikkema?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText",
    by: "Kelly Sikkema",
    from: "Unsplash",
    img_name: 'tax-witholding.jpg',
  },
];

const mainFeaturedPost = {
  title: 'Dutch Taxes Explained',
  description:
    "An introduction to the Dutch system of taxation from an American perspective. Read about the 'box' system of Dutch taxation, when taxes are due, and other taxation basics.",
  image: 'pile_of_coins.jpg',
  imageText: 'Three piles of coins',
  linkText: 'Continue Reading...',
  linkTo: '/taxes/dutchtaxationbasics',
};

const featuredPosts = [
  {
    title: 'Effective Tax Rate Comparison Calculator',
    description:
      'Curious about how tax rates compare between the US and the Netherlands?',
    image: 'calculator.jpg',
    imageLabel: 'A calculator',
    to: '/taxes/effectivetaxcalculator',
    linkText: 'Click here to estimate your taxes...',
    extlink: false,
  },
  {
    title: 'Wealth Tax Calculator',
    description:
      'Use this calculator to estimate your Box 3 taxes',
    image: 'box3-cash.jpg',
    imageLabel: 'hand holding dollars and euros',
    to: '/taxes/box-3-tax-calc',
    linkText: 'Click here to compute...',
    extlink: false,
  },
  {
    title: 'How Bonuses are Taxed in the Netherlands',
    description:
      'Are bonuses and holiday pay taxed at higher rates?',
    image: 'tax-withholding.jpg',
    imageLabel: 'tax withholding form',
    to: '/taxes/how-bonus-taxed-netherlands',
    linkText: 'Click here to find out...',
    extlink: false,
  },
  {
    title: 'Box 3 versus RMDs',
    description:
      'What is the best strategy to manage  your U.S. pre-tax retirement accounts if you reside in the Netherlands?',
    image: 'Box3_RMDs_thumbnail.png',
    imageLabel: 'RMDs box3 text',
    to: '/taxes/managing-us-retirement-accounts-in-the-netherlands',
    extlink: false,
  },
  {
    title: 'Box 3 Taxation: Old vs. New',
    description:
      'A comparison of the current and newly proposed box 3 taxation systems',
    image: 'dog_in_box_3.jpeg',
    imageLabel: 'Dog in a box',
    to: '/taxes/old-versus-new-box-3-taxation-comparison',
    extlink: false,
  },
];

const theme = createTheme();

export default function Taxes() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg">
        <Helmet>
          <title>American Burger Taxes</title>
          <meta name="description" content="A complete guide to Dutch taxes" />
        </Helmet>
        <Header title="The American Burger's Guide to Taxes" sections={sections} />
        <main>
          <MainFeaturedPost post={mainFeaturedPost} />
          <Grid container spacing={4}>
            {featuredPosts.map((post) => (
              <FeaturedPost key={post.title} post={post} />
            ))}
          </Grid>
        </main>
      </Container>
      <Footer
        imageattr={imageAttribution}
      />
    </ThemeProvider>
  );
}
