import {  useRoutes, Navigate } from "react-router-dom";
import Blog from './Blog';
import Taxes from './Taxes';
import ExpatTools from './ExpatTools';
import EffectiveTaxCalculator from './EffectiveTaxCalculator'
import StoreMap from './StoreMap'
import ComingSoon from './ComingSoon'
import RenderPageFromMd from './RenderPageFromMd'
import Contact from './Contact'
import Archives from './Archives'
import CookieConsent from "react-cookie-consent";
import Box3TaxCalculator from './Box3Calculator';
import Box3TaxCalculator22 from './Box3Calculator22';
import Recommendations from './Recommendations';
import HomeBuyingCosts from './HomeBuyingCost';
import PerpErfpachtCalc from './PerpErfpachtCalc';
import Housing from './Housing';
import Box3vsRMDs from './Box3vsRMDs';
import NewOldBox3 from './NewvsOldBox3';
import MortgageDeductionCalc from './MortgageDeduction'

export const routes = [
  { path: '/', element: <Blog />, breadcrumb: null},
  { path: "/archives", element: <Archives />, breadcrumb: null},
  { path: "/comingsoon", element: <ComingSoon />, breadcrumb: null},
  { path: "/contact", element: <Contact />, breadcrumb: null},
  { path: "/dutchtaxationbasics", element: <Navigate to="/taxes/dutchtaxationbasics"/>, breadcrumb: null},
  { path: "/etrcalculatorexplained", element: <Navigate to="/taxes/effectivetaxcalculator/etrcalculatorexplained"/>, breadcrumb: null},
  { path: "/etrcalculatorexamples", element: <Navigate to="/taxes/effectivetaxcalculator/etrcalculatorexamples"/>, breadcrumb: null},
  { path: "/english-books-kids-netherlands",
    element: <RenderPageFromMd filename="english-books-kids-netherlands" title="Guide to Finding English Books for Kids in the Netherlands" desc="Where to find English books for children in the Netherlands" noheadings={false} htype="allheadings"/>,
    breadcrumb: null},
  { path: "/expattools", element: <ExpatTools />, breadcrumb: 'Expat Tools'},
  { path: "/expattools/cheapest-money-transfer-us-netherlands",
    element: <RenderPageFromMd filename="revolut" title="Guide to Cheap Money Transfers from the U.S. to the Netherlands" desc="The cheapest way to transfer money from the U.S. to the Netherlands using a Revolut account" noheadings={true}/>,
    breadcrumb: 'Money Transfer'
  },
  { path: "/expattools/virtualmailboxes",
    element: <RenderPageFromMd filename="virtual-mailbox" title="Guide to Virtual Mailboxes" desc="How to handle snail mail delivered to your US address as an expat in the Netherlands" noheadings={true}/>,
    breadcrumb: 'Virtual Mailboxes'
  },
  { path:"/glossary",
    element: <RenderPageFromMd filename="glossary" title="Glossary" desc="Glossary of terms used by posts on the American Burger site" noheadings={false} htype="allheadings"/>,
    breadcrumb: null
  },
  { path: "/home-buying-costs", element: <Navigate to="/housing/home-buying-costs"/>, breadcrumb: null},
  { path: "/housing", element: <Housing />, breadcrumb: 'Housing'},
  { path: "/housing/home-buying-costs", element: <HomeBuyingCosts />, breadcrumb: 'Home Purchase Calculator'},
  { path:"/housing/mortgage-interest-deduction-calculator-netherlands", element: <MortgageDeductionCalc />, breadcrumb: 'Mortgage Interest Deduction Calculator'},
  { path:"/housing/perpetual-erfpacht-calculator", element: <PerpErfpachtCalc />, breadcrumb: 'Perpetual Erfpacht Purchase Calculator'},
  { path: "/junkmail",
    element: <RenderPageFromMd filename="junk-mail" title="Guide to Junk Mail" desc="How to deal with junk mail in the Netherlands" noheadings={true}/>,
    breadcrumb: null
  },
  { path: "/recommendations", element: <Recommendations />, breadcrumb: null},
  { path: "/storemap", element: <StoreMap />, breadcrumb: null},
  { path: "/taxes", element: <Taxes />, breadcrumb: 'Taxes'},
  { path:"/taxes/box-3-tax-calc", element: <Box3TaxCalculator />, breadcrumb: 'Box 3 Calculator'},
  { path:"/taxes/box-3-tax-calc-2022", element: <Box3TaxCalculator22 />, breadcrumb: 'Box 3 Calculator 2022'},
  { path:"/taxes/managing-us-retirement-accounts-in-the-netherlands", element: <Box3vsRMDs />, breadcrumb: 'Box 3 vs RMDs'},
  { path:"/taxes/old-versus-new-box-3-taxation-comparison", element: <NewOldBox3 />, breadcrumb: 'Old vs New Box 3'},
  { path: "/taxes/dutchtaxationbasics",
    element: <RenderPageFromMd filename="dutch-tax-intro" title="Introduction to Dutch Taxes" desc="An introduction to the Dutch box system of taxation" noheadings={false} htype="allheadings"/>,
    breadcrumb: 'Dutch Taxation Basics'
  },
  { path: "/taxes/effectivetaxcalculator", element: <EffectiveTaxCalculator />, breadcrumb: 'Effective Tax Calculator'},
  { path: "/taxes/effectivetaxcalculator/etrcalculatorexplained",
    element: <RenderPageFromMd filename="eff-tax-calc" title="Effective Tax Rate Calculator Explained" desc="A guide to the calculator. How do you use the inputs and how does the calculator work?" noheadings={false} htype="h1headings"/>,
    breadcrumb: 'Calculator Explained'
  },
  { path: "/taxes/effectivetaxcalculator/etrcalculatorexamples",
    element: <RenderPageFromMd filename="eff-tax-calc-examples" title="Comparison of Effective Tax Rates" desc="Case studies comparing effective tax rates in the US and the Netherlands" noheadings={false} htype="h1headings"/>,
    breadcrumb: 'Case Studies'
  },
  { path: "/taxes/how-bonus-taxed-netherlands",
    element: <RenderPageFromMd filename="how-supplemental-income-taxed" title="Guide to Taxation of Supplemental Income" desc="An explanation of how bonuses and other supplementatl income types are taxed in the Netherlands.?" noheadings={false} htype="h1headings"/>,
    breadcrumb: 'Supplemental income taxation'
  },
  { path: "/visiting-efteling-winter-children",
    element: <RenderPageFromMd filename="efteling" title="Guide to Visiting Efteling in the Winter" desc="Tips and Tricks to visit Efteling in the winter with children aged 8 and 4" noheadings={false} htype="allheadings"/>,
    breadcrumb: null,
  },
  { path: "*",
    element: <RenderPageFromMd filename="page-not-found" title="Page Not Found" desc="Page not found! This page does not exist on this website." noheadings={true}/>,
    breadcrumb: null
  },
];

function MyRouter() {

  let element = useRoutes(routes);

  return element;
}

function App() {

  return (
    <div className="App">
      <MyRouter />
      <CookieConsent>This website uses cookies to enhance user experience.</CookieConsent>
    </div>
  );
}

export default App;
