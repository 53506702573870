import * as React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import MailchimpSubscribe from "react-mailchimp-subscribe"
import {useState, useEffect} from 'react';
import TextField from '@mui/material/TextField';

const SubscribeForm = ({ status, message, onValidated }) => {

  const [email, setEmail] = useState('your@email.com');
  const [statusmsg, setStatusMsg] = useState('');
  const [iserror, setError] = useState(false);

  var re = /\S+@\S+\.\S+/;

  const handleEmailChange = (event) => {
      setEmail(event.target.value);    
  };

  const handleFocus = (event) => {
    setError(false);
    setStatusMsg('');
  }

  const handleSubmit = (e) => {
      e.preventDefault();
      if (re.test(email)) {
        setError(false);
        setStatusMsg('');
        email &&
        email.indexOf("@") > -1 &&
        onValidated({
            EMAIL: email,
        });
      } else {
        setStatusMsg('Invalid email address');
        setError(true);
      }

  }

  useEffect(() => {
      if(status === "success") {
        clearFields();
        setStatusMsg(message);
      }
      if (status === "error") {
        clearFields();
        setStatusMsg(message);
      }
      if (status === "sending") {
        clearFields();
        setStatusMsg(message);
      }
  }, [status, message])

  const clearFields = () => {
      setEmail('');
  }


  return (
      <form onSubmit={(e) => handleSubmit(e)}>

        <div>
          <TextField
              id="subscribe-email"
              label="Email"
              size="small"
              margin="dense"
              fullWidth
              variant="outlined"
              error ={iserror}
              value={email}
              helperText={statusmsg}
              onChange={handleEmailChange}
              onFocus={handleFocus}
              InputLabelProps={{
                shrink: true,
              }}
            /> 
        </div>
        <input
          type='submit'
          value='subscribe'
        />   
      </form>
  );
}

const MailchimpForm = props => {
  // URL from Mailchimp's embedded form builder
  const url = `https://gmail.us14.list-manage.com/subscribe/post-json?u=d687cc46b6ed3f557f792f617&amp;id=954e824e49`;

  return (

      <div>
          <MailchimpSubscribe
              url={url}
              render={({ subscribe, status, message }) => (
                  <SubscribeForm
                      status={status}
                      message={message}
                      onValidated={formData => subscribe(formData)}
                  />
              )}
          />
      </div>

  )
}

function Sidebar(props) {
  const { description, social, title } = props;

  return (
    <Grid item xs={12} md={4} order={{ md: 2, xs: 1 }}>
      <Paper variant="outlined" elevation={0} sx={{ p: 2, bgcolor: 'blue.200' }}>
        <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
          FOMO? Sign up here to be notified of new content!
        </Typography>
        <MailchimpForm />
      </Paper>
      <Paper elevation={0} sx={{ p: 2, bgcolor: 'grey.200' }}>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <Typography>{description}</Typography>
      </Paper>   
      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Connect
      </Typography>
      {social.map((network) => (
        <Link
          display="block"
          variant="body1"
          href={network.link}
          target="_blank"
          rel="noopener"
          key={network.name}
          sx={{ mb: 0.5 }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <network.icon />
            <span>{network.name}</span>
          </Stack>
        </Link>
      ))}
    </Grid>
  );
}

Sidebar.propTypes = {
  description: PropTypes.string.isRequired,
  social: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.elementType.isRequired,
      name: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
};

export { MailchimpForm }; 
export default Sidebar;
