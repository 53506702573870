import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from './Header';
import Footer from './Footer';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import { sections } from './Blog';
import { Helmet } from "react-helmet";
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Switch from '@mui/material/Switch'
import { orange } from '@mui/material/colors';
import { styled } from '@mui/system';
import { alpha } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';

const theme = createTheme();

const usd_to_eur = 0.92;

/*
 * Store the results of all intermediate calculations so that we can display 
 * them if the user flips the 'show me the math' switch.
 */
const calcs = {
  total_assets: 0,
  exempt_assets: 0,
  exemption: 0,
  green_assets: 0,
  green_exemption: 0,
  taxable_green_assets: 0,
  green_tax_credit: 0,
  liabilities: 0,
  liability_threshold: 0,
  liabilities_over_threshold: 0,
  taxable_assets: 0,
  brackets: [0, 0, 0],
  returns: [0, 0, 0],
  box3_tax: [0, 0, 0],
  total_box3_tax: 0,
  final_box3_tax: 0,
}

function resetCalcs() {
  Object.keys(calcs).forEach(k => {
    if (k === 'brackets' || k === 'returns' || k === 'box3_tax') {
      calcs[k][0] = 0;
      calcs[k][1] = 0;
      calcs[k][2] = 0;
    } else {
      calcs[k] = 0;  
    }    
  });
}

/*
 * When the user flips the switch, show them the math. This table shows how the box3
 * tax was calculated.
 */
function ShowMath(props) {  
  if (props.show) {
    return (
      <TableContainer>
        <Table aria-label="simple table">
          <TableBody> 
            <TableRow>
              <TableCell>Total Assets</TableCell>
              <TableCell></TableCell>
              <TableCell>€{calcs.total_assets}</TableCell>
            </TableRow>
          <TableRow>
              <TableCell>Exempt assets (traditional 401k or IRA)</TableCell>
              <TableCell>- €{calcs.exempt_assets}</TableCell>
              <TableCell/>
          </TableRow>
          <TableRow>
              <TableCell>Box 3 exemption</TableCell>
              <TableCell>- €{calcs.exemption}</TableCell>
              <TableCell/>
          </TableRow>
          <TableRow>
              <TableCell>Green assets</TableCell>
              <TableCell>- €{calcs.green_assets}</TableCell>
              <TableCell/>
          </TableRow>
          <TableRow>
              <TableCell>Green asset exemption</TableCell>
              <TableCell>+ €{calcs.green_exemption}</TableCell>
              <TableCell/>
          </TableRow>
          <TableRow>
              <TableCell>Taxable green assets</TableCell>
              <TableCell/>
              <TableCell>€{calcs.taxable_green_assets}</TableCell>
          </TableRow>
          <TableRow>
              <TableCell>Liabilities</TableCell>
              <TableCell>- €{calcs.liabilities}</TableCell>
              <TableCell/>
          </TableRow>
          <TableRow>
              <TableCell>Liability threshold</TableCell>
              <TableCell>+€{calcs.liability_threshold}</TableCell>
              <TableCell/>
          </TableRow>
          <TableRow>
              <TableCell>Liabilities over threshold</TableCell>
              <TableCell>- €{calcs.liabilities_over_threshold}</TableCell>
              <TableCell/>
          </TableRow>
          <TableRow>
              <TableCell>Taxable assets</TableCell>
              <TableCell/>
              <TableCell>€{calcs.taxable_assets}</TableCell>
          </TableRow>
          <TableRow>
              <TableCell />
              <TableCell><b>% of assets assumed to make return of {box3_consts['lower_return'] * 100}%</b></TableCell>
              <TableCell><b>% of assets assumed to make return of {box3_consts['higher_return'] * 100}%</b></TableCell>
              <TableCell><b>Total return</b></TableCell>
              <TableCell><b>Return taxed at {box3_consts['rate'] * 100}%</b></TableCell>
          </TableRow>
          <TableRow>
              <TableCell>Taxable assets in bracket 1 = €{calcs.brackets[0]}</TableCell>
              <TableCell>{brackets[0].low_return_percent * 100}% * €{calcs.brackets[0]} * {box3_consts['lower_return'] * 100}%</TableCell>
              <TableCell>{brackets[0].high_return_percent * 100}% * €{calcs.brackets[0]} * {box3_consts['higher_return'] * 100}%</TableCell>
              <TableCell>€{calcs.returns[0].toFixed(2)}</TableCell>
              <TableCell align="right">€{calcs.box3_tax[0].toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
              <TableCell>Taxable assets in bracket 2 = €{calcs.brackets[1]}</TableCell>
              <TableCell>{brackets[1].low_return_percent * 100}% * €{calcs.brackets[1]} * {box3_consts['lower_return'] * 100}%</TableCell>
              <TableCell>{brackets[1].high_return_percent * 100}% * €{calcs.brackets[1]} * {box3_consts['higher_return'] * 100}%</TableCell>
              <TableCell>€{calcs.returns[1].toFixed(2)}</TableCell>
              <TableCell align="right">€{calcs.box3_tax[1].toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
              <TableCell>Taxable assets in bracket 3 = €{calcs.brackets[2]}</TableCell>
              <TableCell>€0</TableCell>
              <TableCell>{brackets[2].high_return_percent * 100}% * €{calcs.brackets[2]} * {box3_consts['higher_return'] * 100}%</TableCell>
              <TableCell>€{calcs.returns[2].toFixed(2)}</TableCell>
              <TableCell align="right">€{calcs.box3_tax[2].toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
              <TableCell rowSpan={3} />
              <TableCell rowSpan={3} />
              <TableCell rowSpan={3} />
              <TableCell>Total Box 3 tax</TableCell>
              <TableCell align="right">€{calcs.total_box3_tax.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
              <TableCell>Tax credit for green investments</TableCell>
              <TableCell align="right">- €{calcs.green_tax_credit.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
              <TableCell>Box 3 tax owed</TableCell>
              <TableCell align="right">€{calcs.final_box3_tax.toFixed(2)}</TableCell>
          </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
  return null;
}

const box3_consts = {
  exemption: 50650, /* Amount exempt from box3 taxes, doubled with fiscal partner */
  rate: .31, /* box3 tax rate */
  lower_return: -.0001, /* lower fictitious return rate */
  higher_return: .0553, /* higher fictitious return rate */
  debt_threshold: 3200, /* only debt above this amount can be deducted, doubled with fiscal partner */
  green_exemption: 61215, /* Higher exemption for green investments, doubled with fiscal partner */
  green_tax_credit_rate: .007 /* Rate of green tax credit */
};


class box3_brackets {
  constructor(bracket_start, bracket_end, low_return_percent, high_return_percent, max_return) {
    this.bracket_start = bracket_start;
    this.bracket_end = bracket_end;
    this.low_return_percent = low_return_percent;
    this.high_return_percent = high_return_percent;
    this.max_return = max_return;
  }
}

/* 
 * [Bracket start, bracket end, % assets assumed to generate lower returns,
 *  % of assets assumed to generate higher returns, max return at top of bracket]
 * Brackets double with a fiscal partner.
 * Reference: https://www.belastingdienst.nl/wps/wcm/connect/bldcontentnl/belastingdienst/prive/vermogen_en_aanmerkelijk_belang/vermogen/belasting_betalen_over_uw_vermogen/grondslag_sparen_en_beleggen/berekening-2022/
 */
const brackets = [
  new box3_brackets(0, 50651, .67, .33, 0),
  new box3_brackets(50652, 962351, .21, .79, 919.3),
  new box3_brackets(962352, 100000000, 0, 1, 40733.5),  
];

function calcBox3TaxInternal(assets, green_assets, fiscal_partner) {

  calcs.exemption = box3_consts['exemption'];
  calcs.green_exemption = box3_consts['green_exemption'];
  var tax = 0;
  var returns = 0;
  var ub, lb;

  if (fiscal_partner) {
    calcs.exemption *= 2;
    calcs.green_exemption *= 2;
  }

  /* 
   * 'Green investments', so designated by the belastingdienst, have a higher
   * exemption threshold and also result in a tax credit.
   */
  calcs.taxable_green_assets = Math.max(0, green_assets - calcs.green_exemption);
  calcs.taxable_assets = assets - calcs.exemption + calcs.taxable_green_assets;
  const basis_for_green_credit = Math.min(calcs.green_exemption, green_assets);
  calcs.green_tax_credit = basis_for_green_credit * box3_consts['green_tax_credit_rate'];

  if (calcs.taxable_assets <= 0) {
    calcs.taxable_assets = 0;
    return 0;
  }
 
  for (var i = 0; i < brackets.length; i++) {
    ub = fiscal_partner ? brackets[i].bracket_end * 2 : brackets[i].bracket_end;
    if (calcs.taxable_assets > ub) {
        calcs.brackets[i] = ub;
        calcs.returns[i] = (fiscal_partner ? brackets[i+1].max_return * 2 : brackets[i+1].max_return) - 
                            (i > 0 ? calcs.returns[i - 1] : 0);
        calcs.box3_tax[i] = calcs.returns[i] * box3_consts['rate'];
        calcs.total_box3_tax += calcs.box3_tax[i];
        continue;
    }
    lb = fiscal_partner ? brackets[i].bracket_start * 2 : brackets[i].bracket_start;
    calcs.brackets[i] = calcs.taxable_assets - lb;

    returns = fiscal_partner ? brackets[i].max_return * 2 : brackets[i].max_return;
    calcs.returns[i] = (calcs.brackets[i] * brackets[i].low_return_percent * box3_consts['lower_return']) +
                       (calcs.brackets[i] * brackets[i].high_return_percent * box3_consts['higher_return']);
    returns += calcs.returns[i];
    calcs.box3_tax[i] = calcs.returns[i] * box3_consts['rate'];
    calcs.total_box3_tax += calcs.box3_tax[i];
    tax = returns * box3_consts['rate']; 
            
    break;
  }
  calcs.final_box3_tax =  Math.max(0, (tax - calcs.green_tax_credit));
  return calcs.final_box3_tax;
}

function calcBox3Tax(assets_usd, assets_eur, green_assets, liabilities_usd, liabilities_eur, fp, t401k, tIRA) {

  resetCalcs();
  
  calcs.liabilities = (Number(liabilities_usd) * usd_to_eur) + Number(liabilities_eur);
  calcs.liability_threshold = fp ? box3_consts['debt_threshold'] * 2 : box3_consts['debt_threshold'];
  calcs.liabilities_over_threshold = Math.max(0, calcs.liabilities - calcs.liability_threshold);  
  calcs.total_assets = (Number(assets_usd) * usd_to_eur) + Number(assets_eur); 
  calcs.exempt_assets = (Number(t401k) + Number(tIRA)) * usd_to_eur;   
  calcs.green_assets = Number(green_assets);                                           
  var assets = calcs.total_assets - calcs.liabilities_over_threshold - calcs.exempt_assets - calcs.green_assets;
  
  if (assets > 0) {
    var box3_taxes = calcBox3TaxInternal(assets, calcs.green_assets, fp);
    var box3_eff_rate = (box3_taxes * 100)/calcs.total_assets;
    return [box3_taxes.toFixed(2), box3_eff_rate.toFixed(2)];
  } else {
    return [0, 0];
  }

}

const fpChoices = [
  {
    value: true,
    label: 'Yes',
  },
  {
    value: false,
    label: 'No',
  },
];

const OrangeSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: orange[600],
    '&:hover': {
      backgroundColor: alpha(orange[600], theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: orange[600],
  },
}));


export default function Box3TaxCalculator22() {

  let tax = 0;
  let etr = 0;

  const [assets_usd, setUSDAssets] = React.useState(0);
  const handleUSDAssetsChange = (event) => {
    setUSDAssets(event.target.value);
  };

  const [assets_eur, setEURAssets] = React.useState(0);
  const handleEURAssetsChange = (event) => {
    setEURAssets(event.target.value);
  };

  const [green_assets, setGreenAssets] = React.useState(0);
  const handleGreenAssetsChange = (event) => {
    setGreenAssets(event.target.value);
  };

  const [liabilities_usd, setLiabilitiesUSD] = React.useState(0);
  const handleLiabilitiesUSDChange = (event) => {
    setLiabilitiesUSD(event.target.value);
  };

  const [liabilities_eur, setLiabilitiesEUR] = React.useState(0);
  const handleLiabilitiesEURChange = (event) => {
    setLiabilitiesEUR(event.target.value);
  };

  const [t401k, set401k] = React.useState(0);
  const handle401kChange = (event) => {
    set401k(event.target.value);
  };

  const [tIRA, settIRA] = React.useState(0);
  const handleIRAChange = (event) => {
    settIRA(event.target.value);
  };

  const [fp, setFp] = React.useState(false);
  const handleFiscalPartnerChange = (event) => {
    setFp(event.target.value);
  };

  const [box3tax, setBox3Tax] = React.useState(0);
  const [box3etr, setBox3Etr] = React.useState(0);

  const [checked, setChecked] = React.useState(false);

  const switchHandler = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg">
        <Helmet>
          <title>Box 3 Tax Calculator</title>
          <meta name="description" content="A calculator to compute wealth tax, also known as Box 3 tax (for 2022), in the Netherlands" />
        </Helmet>
        <Header title="The American Burger's Box 3 Tax Calculator for 2022" sections={sections} />
        <main>
          <Paper
            sx={{
              position: 'relative',
              p: { xs: 2, md: 4 },
              mb: 2,
            }}
          >
            <Typography variant="body2" paragraph>
              This box 3 calculator is based on the old system of box 3 taxation that was in effect in 2022.<br /><br /> 

              The belastingdienst assumes that you made returns on your assets, and taxes those returns. The returns are 
              calculated based on <a href="https://www.belastingdienst.nl/wps/wcm/connect/bldcontentnl/belastingdienst/prive/vermogen_en_aanmerkelijk_belang/vermogen/belasting_betalen_over_uw_vermogen/grondslag_sparen_en_beleggen/berekening-2022/" target="_blank" rel="noreferrer">fixed return percentages published by the belastingdienst</a> and have nothing to do with where
              your money is actually invested or what your actual returns were.<br /><br />  

              The tax brackets for box 3 are based on the assumption that if you have a small amount of savings it is likely that 
              most of your money is in a 'safe' place (like a bank account) and therefore not making much in the way of returns. At 
              higher levels of savings they assume that the bulk if your money is invested in something like equities and making a 
              healthy return. These assumptions are what resulted in the court case of December 2021 and the ongoing overhaul of the
              box 3 system. The 'Show me the math' button on this page shows you the details of the various brackets in 2022, the imaginary
              return percentages, and the tax at every bracket.<br /><br />
              
              If you have the 30% ruling, you are <a href="/taxes/dutchtaxationbasics#box3-and-the-30-ruling" target="_blank">exempt from box 3 taxation</a>.<br /><br />
              Disclaimer: I am not a tax professional. This is a hobby project.
              Everything you enter on this page stays in your browser. None of the figures are stored or sent to a server. 
            </Typography>
            <Grid container spacing={1} columns={{ xs: 12, md: 10 }}>
              <Grid item xs={6} md={6}>
                <Accordion> 
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="assets-explain"
                  >
                    <Typography variant="body2">Total Assets</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body2">
                      Add up the balances of all your accounts worldwide. Examples of account types include checking accounts, savings accounts, investment accounts, health savings 
                      accounts (HSAs), IRAs, cryptocurrency. Visit the <a href="https://www.belastingdienst.nl/wps/wcm/connect/bldcontentnl/belastingdienst/prive/vermogen_en_aanmerkelijk_belang/vermogen/wat_zijn_uw_bezittingen_en_schulden/uw_bezittingen/" target="_blank" rel="noreferrer">belastingdienst site for
                      a complete list of assets</a> that must be disclosed. Notably, your owner-occupied residence is not considerd a box 3 asset. Second homes and rental
                      properties do count as box 3 assets. Read the belastingdienst link for details on how to calculate the value of
                      such properties for the purposes of a box 3 declaration.<br />
                      Your assets are assumed to yield a return, and you are taxed on this imaginary return. The rate of return 
                      depends on the box3 tax bracket.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={3} md={2}>
                <TextField
                  id="assets_usd"
                  type="number"
                  value={assets_usd}
                  onChange={handleUSDAssetsChange}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={3} md={2}>
                <TextField
                  id="assets_eur"
                  type="number"
                  value={assets_eur}
                  onChange={handleEURAssetsChange}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">€</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="traditional-explain"
                    >
                      <Typography variant="body2">Traditional 401ks or IRAs</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography variant="body2">
                        If your assets above included any traditional 401k or IRA accounts, enter those balances here. Traditional (aka pre-tax)
                        USA retirement accounts are exempt from box 3 taxation. Note that Roth (after-tax) accounts are typically _not_ exempt from box 3 taxation.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
              </Grid>
              <Grid item xs={3} md={2}>
                <TextField
                  id="401k"
                  label="401k"
                  type="number"
                  value={t401k}
                  onChange={handle401kChange}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={3} md={2}>
                <TextField
                  id="IRA"
                  label="IRA"
                  type="number"
                  value={tIRA}
                  onChange={handleIRAChange}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item container xs={6} md={6}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="green-explain"
                  >
                    <Typography variant="body2">Green Investments</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body2">
                      If your total assets above included any <a href="https://www.belastingdienst.nl/wps/wcm/connect/bldcontentnl/themaoverstijgend/brochures_en_publicaties/overzicht_fondsen_en_beleggingen_met_belastingvoordeel" target="_blank" rel="noreferrer">green investments</a>,
                      enter the amount of those investments here.
                      Certain investments (*Groene beleggingen*) are certified as being environmentally friendly.
                      Money invested in one of these instruments is subject to a higher box 3 exemption amount and 
                      also gets a tax credit. 
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={3} md={2}>
                <TextField
                  id="green_assets"
                  type="number"
                  value={green_assets}
                  onChange={handleGreenAssetsChange}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">€</InputAdornment>,
                  }}
                />
              </Grid> 
              <Grid item xs={6} md={6}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="liabilities-explain"
                  >
                    <Typography variant="body2">Total liabilities</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body2">
                      Visit the <a href="https://www.belastingdienst.nl/wps/wcm/connect/bldcontentnl/belastingdienst/prive/vermogen_en_aanmerkelijk_belang/vermogen/wat_zijn_uw_bezittingen_en_schulden/uw_schulden/" target="_blank" rel="noreferrer">belastingdienst site</a> for
                      a complete list of liabilites that may be included for box3. Do not include mortgage debt for an owner occupied home here unless it is an
                      interest-only mortgage (*aflossingsvrije-hypotheek*). Note that there is a threshold for liabilities, and only
                      liabilities above the threshold will reduce your box 3 tax burden.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={3} md={2}>
                <TextField
                  id="liabilities_usd"
                  type="number"
                  value={liabilities_usd}
                  onChange={handleLiabilitiesUSDChange}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={3} md={2}>
                <TextField
                  id="liabilities_eur"
                  type="number"
                  value={liabilities_eur}
                  onChange={handleLiabilitiesEURChange}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">€</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography variant="body2" paragraph>
                  Do you have a <a href="/taxes/dutchtaxationbasics#filing-status-vs-fiscal-partner" target="_blank">fiscal partner</a>?
                </Typography>
              </Grid>
              <Grid item xs={4} md={2}>
                <TextField
                  id="fiscal-partner"
                  select
                  value={fp}
                  size="small"
                  onChange={handleFiscalPartnerChange}
                >
                  {fpChoices.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid> 
              <Grid item xs={12} md={12} container sx={{alignItems: "center", justifyContent: "center"}}>
                <Button 
                  variant="contained"
                  onClick={() => {
                    [tax, etr] = calcBox3Tax(assets_usd, assets_eur, green_assets, liabilities_usd, liabilities_eur,
                                            fp, t401k, tIRA);
                    setBox3Tax(tax);
                    setBox3Etr(etr);
                  }}
                >
                  Calculate
                </Button>
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography variant="body2" paragraph>
                  Box 3 taxes owed
                </Typography>
              </Grid>
              <Grid item xs={4} md={2}>
                <TextField
                  id="box3-taxes"
                  type="number"
                  value={box3tax}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">€</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography variant="body2" paragraph>
                  Box 3 effective tax rate
                </Typography>
              </Grid>
              <Grid item xs={4} md={2}>
                <TextField
                  id="box3-tax-rate"
                  type="number"
                  value={box3etr}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12} container sx={{alignItems: "center", justifyContent: "center"}}>
                <FormControlLabel
                  control={<OrangeSwitch checked={checked} onChange={switchHandler}/>}
                  label="Show me the math"
                />
              </Grid> 
            </Grid>
            <Grid container>
            <Grid item xs={12}>
              <ShowMath show={checked}/> 
          </Grid> 
          </Grid>
          </Paper>
        </main>
      </Container>
      <Footer/>
    </ThemeProvider>
  );
}