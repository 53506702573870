import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';


function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://theamericanburger.nl/">
        The American Burger's Guide
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function AttributeImg(props) {
  const { src } = props;

  return (
    <Link color="inherit" href={src.bylink} target="_blank" rel="noopener" underline="none">
      {src.by} on {src.from}<br/>
    </Link>
  ); 
}

function AttributeImgHdr(props) {
  const { imageattr } = props;

  if (imageattr === undefined) {
    return null;
  }
  return (
    <Typography
          variant="body2"
          color="text.secondary"
          align="left"
        >
          Photos on this page by:<br/>
          {imageattr.map((attr) => (
            <AttributeImg src={attr} key={attr.by}/>
          ))}
        </Typography>
  );
}

function Footer(props) {
  const { imageattr } = props;
  
  return (
    <Box component="footer" sx={{ bgcolor: 'background.paper', py: 6 }}>
      <Container maxWidth="lg"> 
        <Typography
          variant="body2"
          color="text.secondary"
          align="center"
        >
          <Link color="inherit" href="/contact" underline="none">
            Get in touch
          </Link>
        </Typography>
        <div>
          <AttributeImgHdr imageattr={imageattr}/>
        </div>
        <Copyright />
      </Container>
    </Box>
  );
}

export default Footer;
