import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from './Header';
import Footer from './Footer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader'; 
import ListItemButton from '@mui/material/ListItemButton';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';   
import {Link as RouterLink}  from 'react-router-dom';
import { sections } from './Blog';
import { Helmet } from "react-helmet";
import Paper from '@mui/material/Paper';

const headers = [
  'June 2023',
  'February 2023',
  'June 2022',
  'April 2022',
  'March 2022',
]
const posts = [
  {
    name: 'How Bonuses are Taxed in the Netherlands',
    to: '/taxes/how-bonus-taxed-netherlands',
    header: 'June 2023'
  },
  {
    name: 'Visiting Efteling in the Winter with Children',
    to: '/visiting-efteling-winter-children',
    header: 'February 2023'
  },
  {
    name: 'How I transfered 6 figures from the US to the Netherlands for $94.99',
    to: '/expattools/cheapest-money-transfer-us-netherlands',
    header: 'June 2022'
  },
  {
    name: 'Finding English books for children in the Netherlands',
    to: '/english-books-kids-netherlands',
    header: 'April 2022'
  },
  {
    name: 'Dealing with Junk Mail',
    to: '/junkmail',
    header: 'March 2022'
  },
  {
    name: 'Virtual Mailboxes',
    to: '/expattools/virtualmailboxes',
    header: 'March 2022'
  },
];

function MakeSubheaders(props) {
  const {header} = props;

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const hdrid = {header} + "-subheader";

  return (
    <List dense
      subheader={
        <ListSubheader component="div" id={hdrid}>
        </ListSubheader>
      }
    >
      <ListItemButton onClick={handleClick}>
        <ListItemText primary={header}/>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List dense component="div" disablePadding>
          {posts.map((post) => (
            <ListPost key={post.name} post={post} hdr={header}/>
          ))}
        </List>  
      </Collapse>       
  </List>
  );
}

function ListPost(props) {
  const { post, hdr } = props;

  if (hdr === post.header) {
    return (
      <ListItem component={RouterLink} to={post.to}>
        <ListItemText primary={post.name} />
      </ListItem>
    );
    } else {
      return null;
    }
}

const theme = createTheme();

export default function Archives() {
  
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg">
        <Helmet>
          <title>Archives</title>
          <meta name="description" content="Complete list of posts" />
        </Helmet>
        <Header title="The American Burger's Blog Archives" sections={sections} />
        <Paper
          sx={{
            position: 'relative',
            p: { xs: 2, md: 2 },
            mb: 2,
          }}
        >
          {headers.map((hdr) => (
              <MakeSubheaders header={hdr} key={hdr}/>
          ))}
        </Paper>
      </Container>
      <Footer/>
    </ThemeProvider>
  );
}