import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from './Header';
import Footer from './Footer';
import Typography from '@mui/material/Typography';
import { sections } from './Blog';
import { Helmet } from "react-helmet";
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { MailchimpForm } from './Sidebar';

const theme = createTheme();

export default function Box3vsRMDs() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg">
        <Helmet>
          <title>Managing US Retirement Accounts in the Netherlands</title>
          <meta name="description" content="Strategies to manage taxation (box3, RMDs) of 401ks and IRAs in the Netherlands" />
        </Helmet>
        <Header title="Portfolio Management: Box 3 vs. Required Minimum Distributions" sections={sections} />
        <Paper
          sx={{
            position: 'relative',
            p: { xs: 2, md: 2 },
            mb: 2,
          }}
        >
          <Typography variant="body2" component="div">
            If you are lucky enough to have an investment portfolio that is subject to both box 3 taxation in the Netherlands
            and Required Minimum Distributions (RMDs) in the U.S, what is the best strategy to maximize your portfolio? In 
            this post we are going to talk about various strategies to manage your U.S. investment portfolio now that you are 
            subject to the Dutch tax regime.<br />

            You might think that if you minimize the tax you pay out over your lifetime, that that would be the best strategy 
            for your portfolio. We will see why that intuition does not yield the results we would want.<br /><br />

            This post is relevant for:
            <ul>
              <li>U.S. expats in the Netherlands</li>
              <li>Who intend to stay in the Netherlands for the long term and</li>
              <li>Have an investment portfolio that is at least partially in the U.S. and</li>
              <li>The investment portfolio contains some pre-tax accounts that are not subject to box 3 taxation in the Netherlands. Examples of such accounts are traditional 401k accounts and traditional IRAs.</li>
            </ul>
          </Typography>
          <Typography variant="h5" gutterBottom>
            A summary of RMD management strategy in the U.S.
          </Typography>
          <Typography variant="body2" gutterBottom>
            If you were resident in the U.S, the accepted wisdom is to manage your portfolio such that you minimize your exposure to Required Minimum Distributions (RMDs). 
          </Typography>
          <Typography variant="h6">What are RMDs?</Typography>
          <Typography variant="body2" gutterBottom>
            The IRS mandates that at a certain age (72) you have to start making withdrawals from <a href="https://www.irs.gov/retirement-plans/plan-participant-employee/retirement-topics-required-minimum-distributions-rmds" target="_nofollow">certain retirement accounts</a>. The 
            IRS uses the <a href="https://www.irs.gov/publications/p590b#en_US_2021_publink100089977" target="_nofollow">Uniform Lifetime Tables</a> to
            determine the size of the withdrawals. As you get older, the size of your 
            mandatory withdrawal increases. Withdrawals from your pre-tax accounts are, of course, taxable, and furthermore, 
            they are taxable at ordinary income tax rates. So the long and the short of it is that if you have a large balance 
            built up in your pre-tax accounts, you are going to be facing some hefty tax bills in your 70s and beyond.
          </Typography>
          <Typography variant="h6">Minimizing RMDs</Typography>
          <Typography variant="body2" component="div">
            In order to minimize RMDs, one can try to drain any accounts subject to RMDs before the age at which RMDs begin. There are
            three ways to drain such an account without incurring an early withdrawal penalty:
            <ol>
              <li>Wait until you are 59.5 years old. At this point you can withdraw without penalty.</li>
              <li>Do a ‘Roth conversion’. A Roth conversion can be done at any age without incurring an early withdrawal penalty. To execute a Roth conversion you are basically moving your money from a pre-tax account to a Roth account (and paying income tax on the converted amount). Once the converted money is ‘seasoned’ in the Roth account for 5 years, you can withdraw it without tax or penalty.</li>
              <li>Set up 72(t) Substantially Equal Periodic Payments (SEPP). The mechanics of this are too complicated for this post, so we are going to ignore this option.</li>
            </ol>

            The general idea is to withdraw as much as possible before RMD age at a lower <a href="/glossary#effectivetaxrate">effective tax rate</a> than you would pay once
            RMDs start. Some examples of a good time to withdraw/convert:
            <ul>
              <li>you have no or a low income because you take a sabbatical</li>
              <li>you go back to school</li>
              <li>You retire at age 60. Between ages 60 and 72 would be a good time to pull out funds from your traditional IRA at lower tax rates.</li>
            </ul> 
          </Typography>
          <Typography variant="h5" gutterBottom>How does being an expat in the Netherlands affect portfolio management strategy?</Typography>
          <Typography variant="body2" component="div">
            We have an additional complication to consider: <a href="/taxes/dutchtaxationbasics#box3-taxation-aka-the-wealth-tax">box 3 taxation</a>.<br />
            Unlike U.S. citizens, we are subject to box 3 taxation in the Netherlands. However certain pre-tax accounts like a 
            traditional 401k and a traditional IRA are exempt from box 3 taxation in the Netherlands.

            So we have an interesting choice to make:
            <ul>
              <li>keep our $$ sheltered from box 3 taxation and eventually face the tax burden of large RMDs, or</li>
              <li>perform withdrawals/conversions to minimize RMDs but expose ourselves to higher box 3 taxation.</li>
            </ul>
          </Typography>
          <Typography variant="h5" gutterBottom>Box 3 vs. RMDs</Typography>
          <Typography variant="body2" gutterBottom>
          We are going to take a look at 2 strategies to manage our portfolio and see which one comes out ahead.<br /><br />

          <b>Strategy 1: Minimize box 3 taxation.</b> Let pre-tax accounts grow until the age of RMDs is reached and then pay income tax (box 1 tax in the Netherlands) on the withdrawals.<br />
          <b>Strategy 2: Minimize RMDs.</b> Use Roth conversions to drain pre-tax accounts with the aim of eliminating or minimizing RMDs.<br /><br />

          We will compare these strategies to each other and also to the baseline case: no pre-tax accounts i.e. the entire 
          portfolio is subject to box 3 taxation and there are no RMDs or Roth conversions.
          </Typography>
          <Typography variant="h6" gutterBottom>Case study 1</Typography>
          <Typography variant="body2" component="div" gutterBottom>
            Priya is a 35 year old U.S. expat in the Netherlands. She has an investment portfolio worth $150,000. 30% of her 
            portfolio (so $45,000) resides in a traditional IRA account. We assume the following:
            <ul>
              <li>An average annual rate of return of 5% on her entire portfolio.</li>
              <li>A box 3 tax rate of 31% on portfolio growth every year.</li>
              <li>Any long term capital gains due in the U.S. are offset by box 3 taxes paid in the Netherlands.</li>
              <li>Any income tax due in the U.S. is offset by box 1 taxes paid in the Netherlands.</li>
              <li>Roth conversions generate box 1 income and are taxed at an effective tax rate of 40%.</li>
            </ul> 

            We simulate portfolio growth and taxes paid for 55 years - until Priya turns 90 years old - for the baseline case and 
            for both strategies. For strategy 2, we assume that Priya starts Roth conversions at the age of 60 with the aim of 
            draining her traditional IRA before she turns 72.

            Figure 1 shows the results.
            </Typography>
            <Stack alignItems="center" sx={{ mt: 1, mb: 1 }}>
              <Box 
                component="img"
                sx={{ position:'relative', width: {xs: 300, md: 600, lg:640} }}
                src="/fig1_RMDs_box3.png"
                alt="Case study 1 portfolio growth comparison"
              />
              <Typography variant="body2">
                Figure 1
              </Typography>
              <div>
                <Typography variant="body2">The newsletter links to the excel sheet used to generate these results. Sign up here to get access!</Typography>
                <MailchimpForm />
              </div>
            </Stack>
            <Typography variant="body2" component="div">
              Strategy 1 of minimizing box 3 taxation is the winning strategy with an end portfolio value of <b>$1,317,786</b>.
              Strategy 1 performs better than the baseline (<b>$1,255,188</b>). Strategy 2 comes in last with an end portfolio 
              value of <b>$1,119,327</b> - 15% smaller than the winning strategy.<br /><br />

              So, apart from the fact that strategy 1 wins, what else does the graph show us?
              <ul>
                <li>We see that strategy 1 results in the lowest taxation until age 72 and then there is a big spike in taxes paid once RMDs begin.</li>
                <li>We see that in strategy 2 when Roth conversions start, taxation spikes until Roth conversions end, and from then till the end of the simulation strategy 2 pays the lowest taxes.</li>
                <li>And perhaps most surprising of all, even though we pay the most taxes over the lifetime of the portfolio with strategy 1, this strategy still wins!</li>
              </ul>

              Figure 2a is another view of the portfolio simulation with strategy 1. We see how the traditional IRA account grows 
              in size until age 72, and then slowly decreases.

            </Typography>
            <Stack alignItems="center" sx={{ mt: 1, mb: 1 }}>
              <Box 
                component="img"
                sx={{ position:'relative', width: {xs: 300, md: 600, lg:640} }}
                src="/fig2a_RMDs_box3.png"
                alt="Case study 1 strategy 1 portfolio and taxes"
              />
              <Typography variant="body2">
                Figure 2a
              </Typography>
            </Stack>
            <Typography variant="body2">
              Figure 2b is another view of the portfolio simulation with strategy 2. Here we see the Roth conversions draining 
              the traditional IRA account down to 0.
            </Typography>
            <Stack alignItems="center" sx={{ mt: 1, mb: 1 }}>
              <Box 
                component="img"
                sx={{ position:'relative', width: {xs: 300, md: 600, lg:640} }}
                src="/fig2b_RMDs_box3.png"
                alt="Case study 1 strategy 2 portfolio and taxes"
              />
              <Typography variant="body2">
                Figure 2b
              </Typography>
            </Stack>
            <Typography variant="body2">
              Figure 3 shows us the total tax (box 1 + box 3) paid over the lifetime of the portfolio under both strategies.
            </Typography>
            <Stack alignItems="center" sx={{ mt: 1, mb: 1 }}>
              <Box 
                component="img"
                sx={{ position:'relative', width: {xs: 300, md: 600, lg:640} }}
                src="/fig3_RMDs_box3.png"
                alt="Case study 1 lifetime tax comparison"
              />
              <Typography variant="body2">
                Figure 3
              </Typography>
            </Stack>
            <Typography variant="body2" gutterBottom> 
              We see that with strategy 2 we pay a total of $390,573 in taxes over the course of 55 years, while with strategy 1 
              we pay $414,716. <i>So we pay 6% more taxes with strategy 1 but end up with a portfolio that is 17.7% larger!</i>
            </Typography>
            <Typography variant="h6" gutterBottom>Case Study 2</Typography>
            <Typography variant="body2" component="div">
              Well, you say, are we hamstringing strategy 2 by being too aggressive with our Roth conversions starting at age 60? 
              If we try to drain Priya’s traditional IRA in less than 12 years, we have to make large conversions and pay a lot of 
              income tax. Ok, let's try again. Same setup as before but we will make two changes:
              <ul>
                <li>We now start Roth conversions at age 35 instead of age 60 (and therefore convert a much smaller amount every year).</li>
                <li>We assume an effective box 1 tax rate of 35% instead of 40%.</li>
              </ul>
              Figure 4 shows the results of this simulation. 
            </Typography>
            <Stack alignItems="center" sx={{ mt: 1, mb: 1 }}>
              <Box 
                component="img"
                sx={{ position:'relative', width: {xs: 300, md: 600, lg:640} }}
                src="/fig4_RMDs_box3.png"
                alt="Case study 2 portfolio growth with various strategies"
              />
              <Typography variant="body2">
                Figure 4
              </Typography>
            </Stack>
            <Typography variant="body2" gutterBottom>
              Strategy 2 performed even worse than before - with an end portfolio value of $1,109,709 (compared to $1,119,327 in 
              the previous run)! Total taxes paid over the life of the simulation were $353,491 (compared to $390,573 in the 
              previous run). So we paid even less in lifetime taxes but ended up with a lower portfolio.
            </Typography>
            <Typography variant="h5" gutterBottom>Why does Strategy 2 result in a lower portfolio even though we pay lower taxes overall?</Typography>
            <Typography variant="body2" gutterBottom>
              In a word: compounding. In both simulations we pay less taxes overall with strategy 2 but we pay them earlier. This 
              results in a smaller portfolio earlier on, and therefore a smaller nest egg is left to experience compounding 
              growth over the years. Compounding growth is terribly unintuitive - we almost always tend to underestimate its 
              effect over a long period of time. 
             </Typography>
             <Typography variant="h5" gutterBottom sx={{ mt:2, mb:2 }}>Customizing the Case Studies</Typography>
             <Typography variant="body2">
              I created a spreadsheet that I used to generate the results in this post. The spreadsheet allows you to modify
              the input parameters like portfolio size, size of pre-tax accounts, rate of return etc. to suit your own situation.
              The American Burger newsletter links to this spreadsheet, allowing you to perform your own portfolio experiments. Sign up
              here to get access!
             </Typography>
             <Box
              sx={{
                display: "flex",
                flexGrow: 1,
                maxWidth: 800,
              }}
            >
              <MailchimpForm />
            </Box>  
            <Typography variant="h5" gutterBottom sx={{ mt:2, mb:2 }}>Conclusions</Typography>
            <Typography variant="body2">
              <b>Never underestimate the power of compounding growth</b>. It appears beneficial to allow the pre-tax portions of 
              your portfolio to grow untouched by box 3 taxation for as long as possible. It is important to note that large 
              RMDs can have other effects like causing some percentage of your social security income to become taxable in the 
              U.S. or by pushing your taxable income in the Netherlands above certain thresholds for certain tax credits. The 
              impact of these effects are not covered by this simulation, and I’ll attempt to factor them in in a future post 
              on the subject.
            </Typography>
        </Paper>
      </Container>
      <Footer/>
    </ThemeProvider>
  );
}
