import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from './Header';
import FeaturedPost from './FeaturedPost';
import Footer from './Footer';
import { sections } from './Blog';
import { Helmet } from "react-helmet";
  
const imageAttribution = [
  {
    bylink: "https://unsplash.com/@matheo_jbt?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText",
    by: "Matheo JBT",
    from: "Unsplash",
    img_name: 'ams_house.jpg',
  }, 
  {
    bylink: "https://unsplash.com/@sasun1990?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText",
    by: "Sasun Bughdaryan",
    from: "Unsplash",
    img_name: 'calculator.jpg',
  },
  {
    bylink: "https://unsplash.com/@tierramallorca?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText",
    by: "Tierra Mallorca",
    from: "Unsplash",
    img_name: 'red_house_keys.jpg',
  },
];

const featuredPosts = [
  {
    title: 'Compute the Cost of Buying a Home',
    description:
      'Use this calculator to compute the cost of buying a home in the Netherlands',
    image: 'ams_house.jpg',
    imageLabel: 'Amsterdam House',
    to: '/housing/home-buying-costs',
    extlink: false,
  },
  {
    title: 'Mortgage Interest Deduction Calculator',
    description:
      'How much of your mortgage interest can you actually deduct?',
    image: 'red_house_keys.jpg',
    imageLabel: 'Red house with keys',
    to: '/housing/mortgage-interest-deduction-calculator-netherlands',
    extlink: false,
  },
  {
    title: 'Perpetual Erfpacht Calculator',
    description:
      'Should you pay a lump sum or choose the annual canon?',
    image: 'calculator.jpg',
    imageLabel: 'A calculator',
    to: '/housing/perpetual-erfpacht-calculator',
    extlink: false,
  },
];

const theme = createTheme();

export default function Housing() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg">
        <Helmet>
          <title>Housing</title>
          <meta name="description" content="Housing in the Netherlands: resources and calculators" />
        </Helmet>
        <Header title="The American Burger's Guide to Housing in the Netherlands" sections={sections} />
        <main>
          <Grid container spacing={4} sx={{ mt: 1 }}>
            {featuredPosts.map((post) => (
              <FeaturedPost key={post.title} post={post} />
            ))}
          </Grid>
        </main>
      </Container>
      <Footer
        imageattr={imageAttribution}
      />
    </ThemeProvider>
  );
}
