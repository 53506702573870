import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from './Header';
import Footer from './Footer';
import Typography from '@mui/material/Typography';
import { sections } from './Blog';
import { Helmet } from "react-helmet";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { useState, useEffect } from "react";

const theme = createTheme();

// Compute the transfer tax due on a perpetual leasehold as 2% of 17x the annual canon.
function HandlePerpLease(props) {

  const [canon, setCanon] = React.useState(0);
  const handleCanonChange = (event) => {
    setCanon(event.target.value);
  };

  const [lease_value, setLeaseValue] = React.useState(0);
  const [lease_transfer_tax, setLeaseTransferTax] = React.useState(0);

  useEffect(() => {
    setLeaseValue(canon * 17);
    setLeaseTransferTax(lease_value * .02);
    props.setParentLeaseTransferTax(lease_value * .02);
  }, [canon, lease_value, props]);

  return (
    <>
      According to the <a href="https://wetten.overheid.nl/BWBR0002770/2022-04-01#Bijlage" target='_blank' rel='noreferrer'>appendix of the decree that describes the implementation of transfer taxation</a>,
      the value of a perpetual lease is 17 times the annual canon (rent). If the annual canon is
      (you should be able to get this from the seller or their agent) <input
                                type="number"
                                name="canon"
                                value={canon}
                                onChange={handleCanonChange}
      />, the perpetual lease is valued at <b>€{lease_value}</b>. So the lease transfer 
      tax is <b>€{lease_transfer_tax}</b>.
    </> 
  );
}

// Calculate the transfer tax due on a leasehold that has been paid off for 'n'
// years.
function HandleFixedLease(props) {

  const [canon, setCanon] = React.useState(0);
  const handleCanonChange = (event) => {
    setCanon(event.target.value);
  };

  const [lease_end, setLeaseEnd] = React.useState(0);
  const handleLeaseEndChange = (event) => {
    setLeaseEnd(event.target.value);
  };

  const [lease_value, setLeaseValue] = React.useState(0);
  const [lease_value2, setLeaseValue2] = React.useState(0);
  const [lease_transfer_tax, setLeaseTransferTax] = React.useState(0);

  useEffect(() => {
    setLeaseValue(canon * 17);
    setLeaseValue2(calcLeaseValue(lease_end, canon));
    setLeaseTransferTax((lease_value-lease_value2) * .02);
    props.setParentLeaseTransferTax((lease_value-lease_value2) * .02);
  }, [canon, lease_end, lease_value, lease_value2, props]);

  return (
    <>
      We need the amount of the annual canon (you should be able to get this from the 
      seller or their agent): <input
                                type="number"
                                name="canon"
                                value={canon}
                                onChange={handleCanonChange}
      /> and the year up to which the lease has been bought off: <input
                                                              type="number"
                                                              name="leaseend"
                                                              value={lease_end}
                                                              onChange={handleLeaseEndChange}
      />. The convoluted math to determine the deemed value of a fixed lease is enshrined in 
      the <a href="https://wetten.overheid.nl/BWBR0002770/2022-04-01#Bijlage" target='_blank' rel='noreferrer'>appendix of the decree that describes the implementation of transfer taxation</a>.
      The lease transfer tax is <b>€{lease_transfer_tax}</b>.
    </> 
  );
}

function HandleLeaseTransferTax(props) {

  if (props.type === 'none') {
    return (
      <>
      </> 
    );
  }
  if (props.type === 'perp') {
    return (
      <HandlePerpLease setParentLeaseTransferTax={props.setLeaseTransferTax}/>
    );
  }
  if (props.type === 'fixed') {
    return (
      <HandleFixedLease setParentLeaseTransferTax={props.setLeaseTransferTax}/>
    );
  }
}

// Copy of values from https://wetten.overheid.nl/BWBR0002770/2022-04-01#Bijlage
const lease_values = [
  0.85, 0.64, 0.48, 0.36, 0.28, 0.15
]

function calcLeaseValue(lease_end, canon) {
  let value = 0;
  var now = new Date();
  let num_years = lease_end - now.getFullYear();
  let i = 0;

  if (lease_end === 0) {
    return 0;
  }

  while (num_years) {
    if (num_years >= 5) {
      value += lease_values[i] * 5 * canon;
    } else {
      value += lease_values[i] * num_years * canon;
      break;
    }
    num_years -= 5;
    if (i < 5) {
      // For leases of more than 25 years, the factor remains 0.15
      i++;
    }
  }
  return value;
}

function HandleTransferTax(props) {

  const stt = props.setTransferTax;
  const price = props.price;

  const [age, setAge] = React.useState(0);
  const handleAgeChange = (event) => {
    setAge(event.target.value);
  };
  const [partner_age, setPartnerAge] = React.useState(0);
  const handlePartnerAgeChange = (event) => {
    setPartnerAge(event.target.value);
  };

  useEffect(() => {
    stt(partner_age > 0 ? 
      ((age > 35 || price > 440000 ? .02 * price/2 : 0) +
       (partner_age > 35 || price > 440000 ? .02 * price/2 : 0)
      ) :
      (age > 35 || price > 440000 ? .02 * price : 0)
    );
  }, [stt, price, age, partner_age]);

  if (price > 440000) {
    return (
      <>
        For properties valued over €440,000, the <b>transfer tax is 2%</b> of the property price. In your case the tax is <b>€{props.transfer_tax}</b>.
      </> 
    );
  }
  return (
    <>
      In order to determine the amount of the transfer tax, age and price matter. If you are under 35, and your home costs less than €440,000, you 
      pay <a href="https://www.rijksoverheid.nl/onderwerpen/huis-kopen/vraag-en-antwoord/vrijstelling-overdrachtsbelasting" target='_blank' rel='noreferrer'>no transfer tax</a>.
      Otherwise, the <b>transfer tax is 2%</b> of the property price.
      This transfer tax exemption can be used once in your lifetime. If you are buying the house with a partner, and one of you is
      over 35 years of age, then the older partner will have to pay the 2% transfer tax on their share of the home.
      You are <input
                type="number"
                name="age"
                value={age}
                onChange={handleAgeChange}
      /> years old, and your home-buying partner is (ignore this if you have no partner) <input
                                                      type="number"
                                                      name="age"
                                                      value={partner_age}
                                                      onChange={handlePartnerAgeChange}
      /> years old, so your transfer tax is <b>€{props.transfer_tax}</b>.
    </>
  );
}

// Line items shown in the sidebar. These are not shown in the mobile view.
function ShowLineItems(props) {
  return (
    <>
    <Typography variant="h6">Line items:</Typography>
    <Typography>Mortgage amount: €{props.home_price}</Typography>
    <Typography>Deductible costs: €{props.deductible}</Typography>
    <Typography>Transfer tax: €{props.transfer_tax}</Typography>
    <Typography>Ground lease transfer tax: €{props.lease_transfer_tax}</Typography>
    <Typography>Bank guarantee fee/Deposit: €{props.bank_guarantee_fee}</Typography>
    <Typography>NHG cost: €{props.nhg_cost}</Typography>
    <Typography>Notary costs: €{Number(props.notary_costs) + (props.notary_costs > 0 ? 80 : 0)}</Typography>
    <Typography>Mortgage broker: €{props.mortgage_broker_cost}</Typography>
    <Typography>Appraisal costs: €{props.appraisal}</Typography>
    <Typography>Home inspection: €{props.inspection}</Typography>
    <Typography>Amortized property tax, water, sewage: €{props.house_bill.toFixed(2)}</Typography>
    <Typography>Brokerage: €{props.broker_cost}</Typography>
    <Typography>Insurance premium: €{props.insurance_cost}</Typography>
    <Typography>Translation costs: €{props.translation_cost}</Typography>
    <Typography>Negative interest/money transfer charges: €{props.money_charge}</Typography>
    </>
  );
}

export default function HomeBuyingCosts() {

  const [isDesktop, setDesktop] = useState(window.innerWidth > 900);
  const updateMedia = () => {
    setDesktop(window.innerWidth > 900);
  };

  const [home_price, setHomePrice] = React.useState(0);
  const handleHomePriceChange = (event) => {
    setHomePrice(event.target.value);
  };

  const [transfer_tax, setTransferTax] = React.useState(0);
  const [lease_transfer_tax, setLeaseTransferTax] = React.useState(0);
  const [bank_guarantee_fee, setBankGuaranteeFee] = React.useState(0);
  const [nhg_cost, setNHGCost] = React.useState(0);
  const [house_bill, setHouseBill] = React.useState(0);
  const [water_board_taxes, setWaterBoardTaxes] = React.useState(0);
  const [property_tax, setPropertyTax] = React.useState(0);
  
  const [notary_costs, setNotaryCosts] = React.useState(0);
  const handleNotaryCostsChange = (event) => {
    setNotaryCosts(event.target.value);
  };

  const [lease_type, setLeaseType] = React.useState('none');
  const handleLeaseTypeChange = (event) => {
    setLeaseType(event.target.value);
  };

  const [need_nhg, setNeedNHG] = React.useState('no');
  const handleNeedNHG = (event) => {
    setNeedNHG(event.target.value);
  };

  const [need_bank_guarantee, setNeedBankGuarantee] = React.useState('none');
  const handleNeedBankGuarantee = (event) => {
    setNeedBankGuarantee(event.target.value);
  };

  const [num_in_household, setNumInHousehold] = React.useState('0');
  const handleNumHouseholdChange = (event) => {
    setNumInHousehold(event.target.value);
  };
  const [buy_month, setBuyMonth] = React.useState('0');
  const handleBuyMonthChange = (event) => {
    setBuyMonth(event.target.value);
  };
  const [woz_value, setWozValue] = React.useState(0);
  const handleWozValueChange = (event) => {
    setWozValue(event.target.value);
  };
  const [woz_value_derived, setWozValueDerived] = React.useState(0);
  
  const [woz_value_percent, setWozValuePercent] = React.useState(0);
  const handleWozValuePercentChange = (event) => {
    setWozValue(0);
    setWozValuePercent(event.target.value);
  };
  const [appraisal, setAppraisalValue] = React.useState(0);
  const handleAppraisalChange = (event) => {
    setAppraisalValue(event.target.value);
  };
  const [inspection, setInspectionValue] = React.useState(0);
  const handleInspectionChange = (event) => {
    setInspectionValue(event.target.value);
  };
  const [mortgage_broker_cost, setMortgageBrokerCost] = React.useState(0);
  const handleMortgageBrokerCostChange = (event) => {
    setMortgageBrokerCost(event.target.value);
  };
  const [broker_cost, setBrokerCost] = React.useState(0);
  const handleBrokerCostChange = (event) => {
    setBrokerCost(event.target.value);
  };
  const [insurance_cost, setInsuranceCost] = React.useState(0);
  const handleInsuranceCostChange = (event) => {
    setInsuranceCost(event.target.value);
  };
  const [translation_cost, setTranslationCost] = React.useState(0);
  const handleTranslationCostChange = (event) => {
    setTranslationCost(event.target.value);
  };

  const money_charge = 50;
  const [costs, setCosts] = React.useState(0);
  const deductible = Number(nhg_cost) + notary_costs/2 + Number(mortgage_broker_cost) +  Number(translation_cost) + Number(appraisal);
  const percent = home_price > 0 ? (costs/home_price) * 100 : 0;
  
  useEffect(() => {
    if (need_bank_guarantee === 'yes') {
      setBankGuaranteeFee(home_price * .01);
    }
    if (need_bank_guarantee === 'no') {
      setBankGuaranteeFee(home_price * .1);
    }
    (home_price <= 405000 && need_nhg === 'yes') ? setNHGCost(0.006 * home_price) : setNHGCost(0);
    setWaterBoardTaxes(57.73 * num_in_household + 123.26 + 
      (woz_value_derived === 0 ? 0.00012483 * woz_value : 0.00012483 * woz_value_derived));
    setWozValueDerived(woz_value_percent * home_price);
    setPropertyTax(woz_value_derived === 0 ? .000431 * woz_value : .000431 * woz_value_derived);
    setHouseBill(Number(buy_month)/12 * (property_tax + water_board_taxes + 152.50 +
                  (Number(num_in_household) === 1 ? 331 : (Number(num_in_household) === 3 ? 441 : 0))));
    setCosts(Number(transfer_tax) + Number(lease_transfer_tax) + Number(nhg_cost) +
        Number(bank_guarantee_fee) + Number(house_bill) + Number(translation_cost) + Number(notary_costs) + 
        Number(mortgage_broker_cost) + Number(broker_cost) + Number(insurance_cost) +
        Number(appraisal) + Number(inspection) + (home_price > 0 ? money_charge : 0) + 
        ((notary_costs > 0) ? 80 : 0));
        window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, [home_price, need_bank_guarantee, buy_month, num_in_household, need_nhg,
      water_board_taxes, woz_value, woz_value_derived, woz_value_percent, property_tax,
      appraisal, bank_guarantee_fee, broker_cost, house_bill, inspection, insurance_cost,
      lease_transfer_tax, mortgage_broker_cost, nhg_cost, notary_costs, transfer_tax, translation_cost]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg">
        <Helmet>
          <title>The Cost of Home Ownership in the Netherlands</title>
          <meta name="description" content="How much does it cost to buy a home in the Netherlands? This calculator computes all the costs associated with home ownership." />
        </Helmet>
        <Header title="The American Burger's Guide to the Costs of Buying a Home in the Netherlands" sections={sections} />
        <main>
          <Paper
            sx={{
              position: 'relative',
              p: { xs: 2, md: 4 },
              mb: 2,
            }}
          >
            <Grid
              container
              spacing={2}
            >
              <Grid item>
                <Typography variant="body2" component="div" sx={{ mt: 2 }} xs={12} md={8}>
                  This fill-in-the-blank style calculator computes the cost of buying a home in the Netherlands. As you fill in the blanks
                  the summary section is updated with the total costs.  We provide default values where possible, but you can customize all the inputs.
                  Some costs are tax-deductible - so while you will have to pay for them up front, you will get some money back when you file your income
                  tax returns. We assume an <a href="/glossary#effectivetaxrate" target='_blank' rel='noreferrer'>effective tax rate</a> of 
                  40% to compute how much money you will get back when you file your tax returns.
                </Typography>
              </Grid>
              <Grid item style={{maxHeight: '100vh', overflow: 'auto'}} sx={{ mt: 2 }} xs={12} md={8} order={{ md: 1, xs: 2 }}>
                <Typography variant="body2" component="div">
                  
                  You find the home of your dreams in the Netherlands, and you offer to buy it for the 
                  princely sum of <input
                                    type="number"
                                    name="home price"
                                    sx={{display:'inline'}}
                                    value={home_price}
                                    onChange={handleHomePriceChange}
                  />. Your offer is accepted! You pay the seller and the house is yours. If only! <br />
                  This calculator assumes that the cost of the home is covered by a mortgage from your friendly neighborhood bank. However, 
                  there are numerous other additional costs associated with a home purchase that you will need to pay for out of pocket.
                  
                  <h3>Transfer tax (*Overdrachtsbelasting*)</h3>
                  After the actual cost of the house itself, the biggest expense is the <b>transfer tax</b>, because it is a percentage
                  of the property price.<br />
                  <HandleTransferTax 
                    price={home_price}
                    setTransferTax={setTransferTax}
                    transfer_tax={transfer_tax}
                  />
                  <br />Note that the rate of 2% only applies to owner-occupied homes. If you are buying a place intending to rent it out
                  the transfer tax is 8%.<br /><br />
                  You may be surprised, as I was, to discover that the 2% transfer tax doesn't apply only to the price of the house. It
                  also applies to the value of the ground lease, if any, that is being transfered to your name.<br />
                  Has the seller bought off the ground lease for the house?  If not, I strongly encourage you to <a href="#bank-guarantee">skip ahead</a> and save yourself
                  the painful mental gymnastics of the next section.<br />
                  You're still here, so we need to compute how much transfer tax you owe on the deemed value of the lease.<br /> 
                  Ok, deep breath. I'll make this as fast and painless as humanly possible. First: did the seller buy
                  off <select value={lease_type} onChange={handleLeaseTypeChange}>
                                                  <option value="none">choose an option</option>
                                                  <option value="perp">the lease in perpetuity</option>
                                                  <option value="fixed">the lease for a fixed number of years</option>
                  </select>?<br />
                  <HandleLeaseTransferTax 
                    type={lease_type}
                    setLeaseTransferTax={setLeaseTransferTax}
                  />

                  <h3 id="bank-guarantee">Bank guarantee (*Bankgarantie waarborgsom*)</h3>
                  When your offer is accepted you will need to pay the seller (actually the notary, the money
                  is held in escrow) a deposit of 10% of the property price. You can <select value={need_bank_guarantee} onChange={handleNeedBankGuarantee}>
                                                  <option value="none">choose an option</option>
                                                  <option value="yes">ask for a bank guarantee for the 10%</option>
                                                  <option value="no">pay the 10% yourself</option>
                  </select>. Banks typically charge 1% of the amount guaranteed. Based on your choice, you will pay <b>€{bank_guarantee_fee}</b> towards
                  the deposit.

                  <h3 id="nhg">National Mortgage Guarantee (*Nationale Hypotheek Garantie*)</h3>
                  The <a href="https://www.nhg.nl/english-summary/" target='_blank' rel='noreferrer'>NHG is 
                  a unique scheme</a> that guarantees that if you can't make your mortgage payments
                  for a certain set of specified conditions, that they will make the mortgage payments on your behalf.
                  They will take over your mortgage payments if you lose your job, if your relationship ends, if your
                  partner dies or if you become disabled. If you take out a mortgage with NHG you typically get a discount
                  on the mortgage interest rate.<br /> 
                  The NHG is available for houses that cost up to €405,000.<br />
                  {home_price > 405000 ?
                  <>You have entered a purchase price higher than the threshold, so the NHG does not apply to you.</> :
                  <>The mortgage guarantee costs 0.6% of the home price. The cost of the mortgage guarantee is tax deductible. 
                    Will you use the NHG? <select value={need_nhg} onChange={handleNeedNHG}>
                                                  <option value="yes">Yes</option>
                                                  <option value="no">No</option>
                    </select> The NHG costs <b>€{nhg_cost}</b> for your home</>}
                 
                  <h3 id="notary">Notary costs</h3>
                  A notary is required to complete a real estate transaction in the 
                  Netherlands. The notary's job is not just to witness the validity of your signatures.
                  The notary has three main responsibilities:
                  <ol>
                    <li>Record a deed of transfer of ownership with the land register (Kadaster). This is the document that certifies that
                      the house belongs to you.
                    </li>
                    <li>Record the mortgage deed with the land register. This document records the terms of your mortgage and the fact that the
                      bank can sell your house to recoup its investment if you fail to meet the terms of your mortgage.
                    </li>
                    <li>Hold any money (like the 10% deposit) during the escrow period, and ensure transfer of funds from the buyer to the
                      seller.
                    </li>
                  </ol>
                  Notary costs are not fixed, and it can pay to shop around. It is also possible that the seller will choose the notary
                  and you may have no choice in the matter. According to <a href="https://www.independer.nl/hypotheek/info/kosten/notaris" target='_blank' rel='noreferrer'> the independer site</a> the
                  average cost of notary services is €1100. We paid close to €2000 in Amsterdam. 
                  Enter your notary costs here: <input
                                    type="number"
                                    name="notary cost"
                                    sx={{display:'inline'}}
                                    value={notary_costs}
                                    onChange={handleNotaryCostsChange}
                  />.
                  The cost of drawing up and recording the mortgage deed is tax deductible, any costs associated with the deed of transfer of ownership
                  are not deductible. Your notary will give you an itemized bill so that you know how much you can deduct. We will assume that half
                  the costs (€{notary_costs/2}) are deductible.
                  <h4 id="registry lookup">WWFT, VIS, BRP , CIR</h4>
                  The notary also has to examine various registries as part of the real estate transaction and will charge for
                  this service.
                  <ul>
                    <li>WWFT (*witwassen en financieren van terrorisme*) Control/compliance checks for money laundering and
                      terrorism financing.
                    </li>
                    <li>VIS (*Verificatie Informatie Systeem*) Identity verification. This register will be used to verify your
                      identification documents.
                    </li>
                    <li>BRP (*Basisregistratie Personen*). This is the register of persons, including marital status and
                      addresses.
                    </li>
                    <li>CIR (*Centraal Insolventieregister*) This is used to check for previous bankruptcies.</li>
                  </ul>
                  This calculator assumes that this service costs €80, and we will add this to the number you entered above.
                  <h3 id="mortgage-broker">Mortgage broker costs (*hypotheekbemiddeling*)</h3>
                  A mortgage broker will help you shop around for and secure a mortgage for your home. The cost of a mortgage
                  broker is tax deductible. This service costs between €2000 and €4000. Enter your mortgage broker costs here: <input
                                    type="number"
                                    name="mortgage broker cost"
                                    sx={{display:'inline'}}
                                    value={mortgage_broker_cost}
                                    onChange={handleMortgageBrokerCostChange}
                  />.
                  <h3 id="appraisal">Appraisal costs (*Taxatiekosten*)</h3>
                  You will need to pay to have your house appraised - a professional will visit the property to determine how
                  much it is worth. Note that the bank will only give you a mortgage up to the amount that the property is worth, as
                  determined by the appraisal. If you overbid, you will be on the hook for the amount above the appraisal cost. 
                  Enter the cost of your appraisal here (as a point of reference if you don't know what to enter here, we paid €550): <input
                                    type="number"
                                    name="appraisal"
                                    sx={{display:'inline'}}
                                    value={appraisal}
                                    onChange={handleAppraisalChange}
                  />.
                  <h3> Technical home inspection (*Bouwtechnische keuring*)</h3>
                  It is wise to have the home inspected (and to make your purchase contract conditional on the results of the 
                  inspection). The cost of the inspection depends on the size of your home, varying between €200 and €500. Enter the cost of your inspection here: <input
                                    type="number"
                                    name="inspection"
                                    sx={{display:'inline'}}
                                    value={inspection}
                                    onChange={handleInspectionChange}
                  />.
                  <h3 id="house-bills">House Bills</h3>
                  Real estate tax, water board taxes and sewage tax are paid annually. If the seller has paid these costs for the year, the costs will 
                  be amortized based on the date of sale. This section will calculate
                  these costs based on the rates in Amsterdam. In Amsterdam the combined bill is called
                  the *gecombineerde aanslag* and is calculated as follows:
                  <ul>
                    <li>Property tax is 0.0431% of the <a href="https://www.amsterdam.nl/en/municipal-taxes/property-valuation-woz/" target='_blank' rel='noreferrer'>WOZ (Wet Waardering Onroerende Zaken) 
                        value</a> of the house. If you have an address for the home you are planning to buy, you 
                        can <a href="https://www.wozwaardeloket.nl/" target='_blank' rel='noreferrer'>look up the WOZ value</a> and 
                        enter it here: <input
                                    type="number"
                                    name="home price"
                                    sx={{display:'inline'}}
                                    value={woz_value}
                                    onChange={handleWozValueChange}
                        />,  or ask us to assume a WOZ value that is  <select value={woz_value_percent} onChange={handleWozValuePercentChange}>
                                      <option value='0'>a % of your</option>
                                      <option value='.9'>90% of your</option>
                                      <option value='.8'>80% of your</option>
                        </select> purchase price. The property tax is <b>€{property_tax}</b>.
                    </li>
                    <li><a href="https://www.waternet.nl/service-en-contact/waterschapsbelasting/kosten-waterschapsbelasting/#kosten2022" target='_blank' rel='noreferrer'>Water board taxes</a> are 
                        made up of a number of line items and the amounts depend on the WOZ value of your house and
                        the size of your household. You have <select value={num_in_household} onChange={handleNumHouseholdChange}>
                                                  <option value='0'> select household size</option>
                                                  <option value='1'>one person</option>
                                                  <option value='3'>two or more people</option>
                        </select> in your household. Your annual bill is <b>{water_board_taxes.toFixed(2)}</b>. 
                    </li>
                    <li>Waste levy is €331 for a single person household and €441 for a multiperson household</li>
                    <li>Sewer connection charges are 152.50</li>
                  </ul>
                  Assuming you buy your house in the month of <select value={buy_month} onChange={handleBuyMonthChange}>
                                                  <option value='0'>choose a month</option>
                                                  <option value='12'>January</option>
                                                  <option value='11'>February</option>
                                                  <option value='10'>March</option>
                                                  <option value='9'>April</option>
                                                  <option value='8'>May</option>
                                                  <option value='7'>June</option>
                                                  <option value='6'>July</option>
                                                  <option value='5'>August</option>
                                                  <option value='4'>September</option>
                                                  <option value='3'>October</option>
                                                  <option value='2'>November</option>
                                                  <option value='1'>December</option>
                  </select> your portion of these bills will be <b>€{house_bill.toFixed(2)}</b>.

                  <h3 id="brokerage">Brokerage costs (*aankoopmakelaar*)</h3>
                  You may choose to use a broker to help you find a home to buy, to advise on the bid amount and process, and
                  to help with negotiations. Some brokers charge a % of the home price, others charge a fixed, pre-agreed upon 
                  amount. Enter the cost of your broker here: <input
                                    type="number"
                                    name="broker cost"
                                    sx={{display:'inline'}}
                                    value={broker_cost}
                                    onChange={handleBrokerCostChange}
                        />.
                  <h3 id="insurance">Home insurance costs</h3>
                  Most mortgage insurance companies will require your home to be insured as a condition of the mortgage. You will
                  need to provide the notary a proof of insurance at the time of deed transfer and mortgage deed registration. So,
                  for the purposes of this calculator we need to add the cost of one month's worth of insurance premium. You can 
                  use <a href="https://www.fbto.nl/woonverzekering/premie-berekenen/stel-samen" target='_blank' rel='noreferrer'>this FBTO calculator</a> to estimate
                  your insurance costs and enter your monthly premium here: <input
                                    type="number"
                                    name="insurance cost"
                                    sx={{display:'inline'}}
                                    value={insurance_cost}
                                    onChange={handleInsuranceCostChange}
                        />.
                  <h3 id="translation">Translation services</h3>
                  If you are not a native Dutch speaker the notary will require you to have a professional translator
                  present at the time of the final signing and transfer of ownership. The notary reads out relevant portions
                  of the mortgage agreement and the transfer of ownership deed in Dutch, and the translator will translate to
                  your native language. The translation service cost us €260. Enter your cost here: <input
                                    type="number"
                                    name="translation cost"
                                    sx={{display:'inline'}}
                                    value={translation_cost}
                                    onChange={handleTranslationCostChange}
                        />.
                  <h3 id="negative-interest">Negative interest and money transfer charges</h3>
                  Interest rates in EU banks are currently negative. This means that your notary will be charged something
                  to hold your money in escrow, and will pass on that cost to you. They may also charge you for money transfers.
                  This calculator assumes these costs are €{money_charge}.
                  <h3 id="conclusion">Conclusion</h3>
                  If you plan to buy a house that is appraised at <b>€{home_price}</b> using a mortgage from a bank, 
                  you should be prepared to pay <b>€{costs.toFixed(2)} </b> in out-of-pocket costs. You will recoup some of 
                  these additional costs when you file your income tax return.
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} order={{ md: 2, xs: 1 }}>
                <Stack>
                  <Typography variant="h5">Summary of costs</Typography>
                  <Typography>Home price: €{home_price}</Typography>
                  <Typography>Additional costs: €{costs.toFixed(2)}</Typography>
                  <Typography>Additional costs as a % of home price: {percent.toFixed(2)}%</Typography>
                  <Typography>Costs reclaimed when income tax filed: €{.4*deductible}</Typography>
                  {isDesktop ? (
                    <ShowLineItems home_price={home_price}
                      deductible={deductible}
                      transfer_tax={transfer_tax}
                      lease_transfer_tax={lease_transfer_tax}
                      bank_guarantee_fee={bank_guarantee_fee}
                      nhg_cost={nhg_cost}
                      notary_costs={notary_costs}
                      mortgage_broker_cost={mortgage_broker_cost}
                      appraisal={appraisal}
                      inspection={inspection}
                      house_bill={house_bill}
                      broker_cost={broker_cost}
                      insurance_cost={insurance_cost}
                      translation_cost={translation_cost}
                      money_charge={money_charge}
                      />
                    ) : (<></>)}
                </Stack>
            </Grid>
            </Grid>
          </Paper>
        </main>
      </Container>
      <Footer/>
    </ThemeProvider>
  );
}
