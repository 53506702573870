import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from './Header';
import Footer from './Footer';
import Main from './Main';
import raw from 'raw.macro';
import { sections } from './Blog';

const theme = createTheme();

console.log("recompile1");

export default function RenderPageFromMd(props) {
  const {filename, title, desc, noheadings, htype} = props;
  const post = raw(`./${filename}.md`);
  const hdr = "The American Burger's " + title;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg">
        <Header title={hdr} sections={sections} />
        <Main post={post} changehead={true} headtitle={title} headdesc={desc} noheadings={noheadings} htype={htype} fullwidth={true}/>
      </Container>
      <Footer />
    </ThemeProvider>
  );
}
