import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from './Header';
import Footer from './Footer';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import { sections } from './Blog';
import { Helmet } from "react-helmet";
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Switch from '@mui/material/Switch'
import { orange } from '@mui/material/colors';
import { styled } from '@mui/system';
import { alpha } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';

const theme = createTheme();

const usd_to_eur = .92;

/*
 * Store the results of all intermediate calculations so that we can display 
 * them if the user flips the 'show me the math' switch.
 */
const calcs = {
  assets: 0,
  total_savings: 0,
  total_investments: 0,
  exempt_assets: 0,
  exemption: 0,
  liabilities: 0,
  liability_threshold: 0,
  liabilities_over_threshold: 0,
  taxable_investments: 0,
  return_on_savings: 0,
  return_on_investments: 0,
  return_on_debt: 0,
  basis_for_taxation: 0,
  total_return: 0,
  taxable_return: 0,
  taxable_ratio: 0,
  total_box3_tax: 0,
}

function resetCalcs() {
  Object.keys(calcs).forEach(k => {
      calcs[k] = 0;     
  });
}

/*
 * When the user flips the switch, show them the math. This table shows how the box3
 * tax was calculated.
 */
function ShowMath(props) {  
  if (props.show) {
    return (
      <TableContainer>
        <Table aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell><b>Step 1: Calculate returns on assets</b></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow> 
            <TableRow>
              <TableCell>Assumed returns on savings</TableCell>
              <TableCell>  €{calcs.return_on_savings.toFixed(2)}</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Assumed returns on taxable investments</TableCell>
              <TableCell>+ €{calcs.return_on_investments.toFixed(2)}</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Return on assets</TableCell>
              <TableCell></TableCell>
              <TableCell>€{(calcs.return_on_investments + calcs.return_on_savings).toFixed(2)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Return on debt</TableCell>
              <TableCell>- €{calcs.return_on_debt.toFixed(2)}</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Total return (R)</TableCell>
              <TableCell></TableCell>
              <TableCell>€{(calcs.total_return).toFixed(2)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><b>Step 2: Calculate the % of your return that is taxable</b></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow> 
            <TableRow>
              <TableCell>Taxable assets - debt - exemption (A)</TableCell>
              <TableCell>€{calcs.basis_for_taxation.toFixed(2)}</TableCell>
              <TableCell/>
            </TableRow>
            <TableRow>
              <TableCell>Taxable assets - debt (B)</TableCell>
              <TableCell>€{calcs.assets.toFixed(2)}</TableCell>
              <TableCell/>
            </TableRow>
            <TableRow>
              <TableCell>% of return that is taxable (Y = A/B)</TableCell>
              <TableCell/>
              <TableCell>{calcs.taxable_ratio.toFixed(2)}%</TableCell>
            </TableRow>
            <TableRow>
              <TableCell><b>Step 3: Calculate box 3 taxes</b></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow> 
            <TableRow>
              <TableCell>Taxable return (Y * R)</TableCell>
              <TableCell>€{calcs.taxable_return.toFixed(2)}</TableCell>
              <TableCell/>
            </TableRow>
            <TableRow>
              <TableCell>Box 3 tax owed = 32% * taxable return</TableCell>
              <TableCell/>
              <TableCell>€{calcs.total_box3_tax.toFixed(2)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
  return null;
}

const box3_consts = {
  exemption: 57000, /* Amount exempt from box3 taxes, doubled with fiscal partner */
  rate: .32, /* box3 tax rate */
  lower_return: .0036, /* lower fictitious return rate */
  higher_return: .0617, /* higher fictitious return rate */
  debt_threshold: 3400, /* only debt above this amount can be deducted, doubled with fiscal partner */
  return_on_debt: .0257, /* return on debt */
  green_exemption: 61215, /* Higher exemption for green investments, doubled with fiscal partner */
  green_tax_credit_rate: .007 /* Rate of green tax credit */
};


function calcBox3Tax(savings_usd, savings_eur, investments_usd, investments_eur, liabilities_usd, liabilities_eur, fp, t401k, tIRA) {

  resetCalcs();
  
  calcs.liabilities = (Number(liabilities_usd) * usd_to_eur) + Number(liabilities_eur);
  calcs.liability_threshold = fp ? box3_consts['debt_threshold'] * 2 : box3_consts['debt_threshold'];
  calcs.liabilities_over_threshold = Math.max(0, calcs.liabilities - calcs.liability_threshold); 
  calcs.return_on_debt = calcs.liabilities_over_threshold * box3_consts['return_on_debt'];
  calcs.total_savings = (Number(savings_usd) * usd_to_eur) + Number(savings_eur); 
  calcs.return_on_savings = calcs.total_savings * box3_consts['lower_return']; 
  calcs.total_investments = (Number(investments_usd) * usd_to_eur) + Number(investments_eur);
  calcs.exempt_assets = (Number(t401k) + Number(tIRA)) * usd_to_eur;
  calcs.taxable_investments =  calcs.total_investments - calcs.exempt_assets;
  calcs.return_on_investments = calcs.taxable_investments * box3_consts['higher_return'];
  calcs.total_return = Math.max(0, calcs.return_on_savings + calcs.return_on_investments - calcs.return_on_debt);                                             
  calcs.assets = Math.max(0, calcs.taxable_investments + calcs.total_savings - calcs.liabilities_over_threshold);
  calcs.basis_for_taxation = Math.max(0, calcs.assets - (fp ? box3_consts['exemption'] * 2 :  box3_consts['exemption']));

  if (calcs.basis_for_taxation > 0) {
    calcs.taxable_ratio = (calcs.basis_for_taxation * 100)/calcs.assets;
    calcs.taxable_return = (calcs.total_return * calcs.taxable_ratio)/100;
    calcs.total_box3_tax = calcs.taxable_return * box3_consts['rate'];
    var box3_eff_rate = (calcs.total_box3_tax * 100)/(calcs.total_savings + calcs.total_investments);
    return [calcs.total_box3_tax.toFixed(2), box3_eff_rate.toFixed(2)];
  } else {
    return [0, 0];
  }

}

const fpChoices = [
  {
    value: true,
    label: 'Yes',
  },
  {
    value: false,
    label: 'No',
  },
];

const OrangeSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: orange[600],
    '&:hover': {
      backgroundColor: alpha(orange[600], theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: orange[600],
  },
}));


export default function Box3TaxCalculator() {

  let tax = 0;
  let etr = 0;

  const [savings_usd, setUSDSavings] = React.useState(0);
  const handleUSDSavingsChange = (event) => {
    setUSDSavings(event.target.value);
  };

  const [savings_eur, setEURSavings] = React.useState(0);
  const handleEURSavingsChange = (event) => {
    setEURSavings(event.target.value);
  };

  const [investments_usd, setUSDInvestments] = React.useState(0);
  const handleUSDInvestmentsChange = (event) => {
    setUSDInvestments(event.target.value);
  };

  const [investments_eur, setEURInvestments] = React.useState(0);
  const handleEURInvestmentsChange = (event) => {
    setEURInvestments(event.target.value);
  };

  const [liabilities_usd, setLiabilitiesUSD] = React.useState(0);
  const handleLiabilitiesUSDChange = (event) => {
    setLiabilitiesUSD(event.target.value);
  };

  const [liabilities_eur, setLiabilitiesEUR] = React.useState(0);
  const handleLiabilitiesEURChange = (event) => {
    setLiabilitiesEUR(event.target.value);
  };

  const [t401k, set401k] = React.useState(0);
  const handle401kChange = (event) => {
    set401k(event.target.value);
  };

  const [tIRA, settIRA] = React.useState(0);
  const handleIRAChange = (event) => {
    settIRA(event.target.value);
  };

  const [fp, setFp] = React.useState(false);
  const handleFiscalPartnerChange = (event) => {
    setFp(event.target.value);
  };

  const [box3tax, setBox3Tax] = React.useState(0);
  const [box3etr, setBox3Etr] = React.useState(0);

  const [checked, setChecked] = React.useState(false);

  const switchHandler = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg">
        <Helmet>
          <title>Box 3 Tax Calculator</title>
          <meta name="description" content="A calculator to compute wealth tax, also known as Box 3 tax, in the Netherlands" />
        </Helmet>
        <Header title="The American Burger's Box 3 Tax Calculator" sections={sections} />
        <main>
          <Paper
            sx={{
              position: 'relative',
              p: { xs: 2, md: 4 },
              mb: 2,
            }}
          >
            <Typography variant="body2" paragraph>
              The Netherlands levies a <a href="/taxes/dutchtaxationbasics#box3-taxation-aka-the-wealth-tax" target="_blank">wealth tax (a.k.a. the box 3 tax)</a> on your assets in lieu of a capital gains tax.
              Certain American account types are exempt from box 3 taxation. Use this calculator to figure out approximately how much you would owe. 
              This calculator uses the <a href="https://www.belastingdienst.nl/wps/wcm/connect/nl/box-3/content/box-3-inkomen-op-voorlopige-aanslag-2023" target="_blank" rel="noreferrer">2023 box 3 rates</a>. Note 
              that the box 3 tax regime is in the process of undergoing an overhaul. In December 2021 the Dutch 
              supreme court ruled that <a href="https://www2.deloitte.com/nl/nl/pages/tax/articles/2017-and-2018-box-3-levy-violates-property-rights-and-prohibition-of-discrimination.html" target="_blank" rel="noreferrer"> 
              the way returns were being taxed in box 3 violated property rights</a>.
              In response to the court the Dutch government is working on a replacement box 3 system that is supposed to go into effect starting in 2026.
              In the interim the government has passed bridging legislation that governs how box 3 taxes will be levied until 2026.
              PWC has a <a href="https://www.pwc.nl/nl/actueel-en-publicaties/belastingnieuws/inkomen/box-3-actualiteiten-belastingplan-2023.html" target="_blank" rel="noreferrer">detailed article on the history of box 3 taxation, that also keeps track of ongoing legislative debates
              and proposals.</a><br /><br />

              If you have the 30% ruling, you are <a href="/taxes/dutchtaxationbasics#box3-and-the-30-ruling" target="_blank">exempt from box 3 taxation</a>.<br /><br />
              
              The old box 3 calculator (circa 2021) can be found <a href="/taxes/box-3-tax-calc-2022" target="_blank">here</a><br /><br />.
              Disclaimer: I am not a tax professional, and this is not tax advice.
              Everything you enter on this page stays in your browser. None of the figures are stored or sent to a server. 
            </Typography>
            <Grid container spacing={1} columns={{ xs: 12, md: 10 }}>
            <Grid item xs={6} md={6}>
                <Accordion> 
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="savings-explain"
                  >
                    <Typography variant="body2">Bank Balances</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body2">
                      Add up the balances (as of Jan 1st) of all your bank accounts (current or savings) worldwide. Include here money that is not
                      invested in stocks, bonds, cryptocurrencies etc. These assets are assumed to earn a lower 'imaginary return',
                      and are thus subject to less taxation under box 3.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={3} md={2}>
                <TextField
                  id="savings_usd"
                  type="number"
                  value={savings_usd}
                  onChange={handleUSDSavingsChange}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={3} md={2}>
                <TextField
                  id="savings_eur"
                  type="number"
                  value={savings_eur}
                  onChange={handleEURSavingsChange}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">€</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <Accordion> 
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="assets-explain"
                  >
                    <Typography variant="body2">Investments and Other Assets</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body2">
                      Add up the balances (as of Jan 1st) of all your investments worldwide. Examples of account types include the balances of investment accounts where you hold stocks, mutual funds, bonds or ETFs, health savings 
                      accounts (HSAs), IRAs, cryptocurrency. Visit the <a href="https://www.belastingdienst.nl/wps/wcm/connect/bldcontentnl/belastingdienst/prive/vermogen_en_aanmerkelijk_belang/vermogen/wat_zijn_uw_bezittingen_en_schulden/uw_bezittingen/" target="_blank" rel="noreferrer">belastingdienst site for
                      a complete list of assets</a> that must be disclosed. Notably, your owner-occupied residence is not considerd a box 3 asset. Second homes and rental
                      properties do count as box 3 assets. Read the belastingdienst link for details on how to calculate the value of
                      such properties for the purposes of a box 3 declaration.<br />
                      Your assets are assumed to yield a return, and you are taxed on this imaginary return.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={3} md={2}>
                <TextField
                  id="investments_usd"
                  type="number"
                  value={investments_usd}
                  onChange={handleUSDInvestmentsChange}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={3} md={2}>
                <TextField
                  id="investments_eur"
                  type="number"
                  value={investments_eur}
                  onChange={handleEURInvestmentsChange}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">€</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="traditional-explain"
                    >
                      <Typography variant="body2">Traditional 401ks or IRAs</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography variant="body2">
                        If your assets above included any traditional 401k or IRA accounts, enter those balances here. Traditional (aka pre-tax)
                        USA retirement accounts are exempt from box 3 taxation. Note that Roth (after-tax) accounts are typically _not_ exempt from box 3 taxation.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
              </Grid>
              <Grid item xs={3} md={2}>
                <TextField
                  id="401k"
                  label="401k"
                  type="number"
                  value={t401k}
                  onChange={handle401kChange}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={3} md={2}>
                <TextField
                  id="IRA"
                  label="IRA"
                  type="number"
                  value={tIRA}
                  onChange={handleIRAChange}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="liabilities-explain"
                  >
                    <Typography variant="body2">Total Liabilities</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body2">
                      Visit the <a href="https://www.belastingdienst.nl/wps/wcm/connect/bldcontentnl/belastingdienst/prive/vermogen_en_aanmerkelijk_belang/vermogen/wat_zijn_uw_bezittingen_en_schulden/uw_schulden/" target="_blank" rel="noreferrer">belastingdienst site</a> for
                      a complete list of liabilites that may be included for box3. Do not include mortgage debt for an owner occupied home here unless it is an
                      interest-only mortgage (*aflossingsvrije-hypotheek*). Note that there is a threshold for liabilities, and only
                      liabilities above the threshold will reduce your box 3 tax burden.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={3} md={2}>
                <TextField
                  id="liabilities_usd"
                  type="number"
                  value={liabilities_usd}
                  onChange={handleLiabilitiesUSDChange}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={3} md={2}>
                <TextField
                  id="liabilities_eur"
                  type="number"
                  value={liabilities_eur}
                  onChange={handleLiabilitiesEURChange}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">€</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography variant="body2" paragraph>
                  Do you have a <a href="/taxes/dutchtaxationbasics#filing-status-vs-fiscal-partner" target="_blank">fiscal partner</a>?
                </Typography>
              </Grid>
              <Grid item xs={4} md={2}>
                <TextField
                  id="fiscal-partner"
                  select
                  value={fp}
                  size="small"
                  onChange={handleFiscalPartnerChange}
                >
                  {fpChoices.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid> 
              <Grid item xs={12} md={12} container sx={{alignItems: "center", justifyContent: "center"}}>
                <Button 
                  variant="contained"
                  onClick={() => {
                    [tax, etr] = calcBox3Tax(savings_usd, savings_eur, investments_usd, investments_eur, liabilities_usd, liabilities_eur,
                                            fp, t401k, tIRA);
                    setBox3Tax(tax);
                    setBox3Etr(etr);
                  }}
                >
                  Calculate
                </Button>
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography variant="body2" paragraph>
                  Box 3 taxes owed
                </Typography>
              </Grid>
              <Grid item xs={4} md={2}>
                <TextField
                  id="box3-taxes"
                  type="number"
                  value={box3tax}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">€</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography variant="body2" paragraph>
                  Box 3 <a href="/glossary#effectivetaxrate" target="_blank">effective tax rate</a>
                </Typography>
              </Grid>
              <Grid item xs={4} md={2}>
                <TextField
                  id="box3-tax-rate"
                  type="number"
                  value={box3etr}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12} container sx={{alignItems: "center", justifyContent: "center"}}>
                <FormControlLabel
                  control={<OrangeSwitch checked={checked} onChange={switchHandler}/>}
                  label="Show me the math"
                />
              </Grid> 
            </Grid>
            <Grid container>
            <Grid item xs={12}>
              <ShowMath show={checked}/> 
          </Grid> 
          </Grid>
          </Paper>
        </main>
      </Container>
      <Footer/>
    </ThemeProvider>
  );
}