import * as React from 'react';
import PropTypes from 'prop-types';
import Toolbar from '@mui/material/Toolbar';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from "@material-ui/core";
import Typography from '@mui/material/Typography';
import { NavLink } from 'react-router-dom';
import { useState, useEffect } from "react";
import MenuIcon from '@material-ui/icons/Menu';
import { grey } from '@mui/material/colors';
import { orange } from '@mui/material/colors';
import Button from '@mui/material/Button';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { routes } from './App';

const useStyles = makeStyles(() => ({
  header: {
    "@media (max-width: 900px)": {
      paddingLeft: 0,
    },
  },
  logo: {
    maxWidth: 60,
  },
  navbar: {
    "&.MuiToolbar-root": {
      backgroundColor: orange[700],
    },
  },
  desktopmenuchoices: {
    "&.active": {
      "&& .MuiTypography-root":{
        textDecoration: 'underline',
      }
    }
  },
  drawermenuchoices: {
    "&.active": {
      "&& .MuiMenuItem-root":{
        backgroundColor: grey[300],
      }
    }
  },
}));

const Breadcrumbs = () => {
  const breadcrumbs = useBreadcrumbs(routes);

  // Don't show breadcrumbs on a top level page
  if (breadcrumbs.length === 1) {
    return null;
  } 

  return (
    <>
      {breadcrumbs.map(({
        match,
        breadcrumb
      }) => (
        <span key={match.pathname}>
          <NavLink 
            to={match.pathname}
          >
            <Typography display="inline" variant="body2" color="text.primary">
              {breadcrumb}
            </Typography>
          </NavLink>
          <span> / </span>
        </span>
      ))}
    </>
  );
}

function Header(props) {
  const { sections, title } = props;
  const { header, logo, desktopmenuchoices, drawermenuchoices, navbar } = useStyles();

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
      setState({});
    }
  }, []);

  const getDrawerChoices = () => {
    return sections.map(({title, url}) => {
      return (
        <NavLink 
          className={drawermenuchoices}
          key={title}
          to={url}
          color="black"
          style={{ textDecoration: 'none' }}
        >
          <MenuItem dense>
            <Typography variant="body2" color="text.primary">
              {title}
            </Typography>
          </MenuItem>
        </NavLink>
        
      );
    });
  };

  const displayDesktop = () => {
    return (
      <React.Fragment>
        <Toolbar className={header} sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <a href="/">
            <img src={process.env.PUBLIC_URL + '/tablogoblack.png'} alt="logo" className={logo} />
          </a>
          <Typography
            component="h2"
            variant="h5"
            color="inherit"
            align="center"
            noWrap
            sx={{ flex: 1 }}
          >
            {title}
          </Typography>
        </Toolbar>
        <Toolbar
          component="nav"
          variant="dense"
          className={navbar}
          sx={{ justifyContent: 'space-between', overflowX: 'auto'}}
        >
          {sections.map((section) => (
            <Button
              size="small"
              color="inherit"
              className={desktopmenuchoices}
              component={NavLink}
              to={section.url}
              key={section.title}
              sx={{ fontSize: 12 }}
            >
              <Typography sx={{fontWeight: 'medium', fontSize: 12}}>{section.title}</Typography>
            </Button>
          ))}
      </Toolbar>
      </React.Fragment>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={header} >
        <IconButton
          {...{
            edge: "start",
            color: "inherit",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: handleDrawerOpen,
          }}
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          {...{
            anchor: "left",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div>{getDrawerChoices()}</div>
        </Drawer>
        <div>{title}</div>
        <a href="/">
          <img src={process.env.PUBLIC_URL + '/tablogoblack.png'} alt="logo" className={logo} />
        </a>
      </Toolbar>
    );
  };

  return (
    <header>
      {mobileView ? displayMobile() : displayDesktop()}
      <Breadcrumbs />
    </header>
  );
}

Header.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
};

export default Header;
