import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from './Header';
import Footer from './Footer';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel';
import { makeStyles } from '@material-ui/core';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Autocomplete from '@mui/material/Autocomplete';
import { useState } from 'react';
import { sections } from './Blog';
import { orange } from '@mui/material/colors';
import { styled } from '@mui/system';
import { alpha } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { Helmet } from "react-helmet";

const USDutchStores = [
  {label: 'Amazon', dutchstore:'Amazon.de + Amazon.nl + Bol.com', note: "Your US amazon credentials will work on amazon sites of other countries, but your prime membership will not carry over"},
  {label: 'Arts and Crafts', dutchstore:'Pipoos', note: ""},
  {label: 'Best Buy', dutchstore:'Coolblue, Mediamarkt, Alternate.nl', note: "Always check tweakers.net for reviews or to set a price watch"},
  {label: 'Christmas', dutchstore:'Intratuin, Action', note: "Christmas decorations at these stores are seasonal offerings"},
  {label: 'Christmas decorations', dutchstore:'Intratuin, Action', note: "Christmas decorations at these stores are seasonal offerings"},
  {label: 'Computers, Laptops, and associated components/accessories', dutchstore:'Azerty, Megekko', note: "Always check tweakers.net for reviews or to set a price watch"},
  {label: 'Container Store', dutchstore: 'No good alternative exists', note: "Mepal and Action have some containers. For pantry containers look for mDesign and InterDesign Stacking Bins on amazon.de"},
  {label: 'Dollar Store', dutchstore:'Action', note: "Action is cheap, but not priced at 1 euro"},
  {label: 'Groceries', dutchstore:'Albert Heijn, Jumbo, Lidl, Aldi, Dirk, Picnic, Spar', note: "Visit https://www.consumentenbond.nl/test/voeding-gezondheid/prijspeiling-huismerken and https://www.consumentenbond.nl/test/voeding-gezondheid/goedkoopste-supermarkt to compare supermarket prices"},
  {label: 'Hardware', dutchstore:'Praxis, Gamma, Hornbach', note: ""},
  {label: 'Home Depot', dutchstore:'Praxis, Gamma, Hornbach', note: ""},
  {label: 'Joann Fabrics', dutchstore: 'Jan Sikkes, stoffen.net', note: "In Amsterdam there are fabric stores in the Albert Cuyp market area"},
  {label: 'Online Photo Printing', dutchstore: 'Albelli, foto.hema.nl', note: ""},
  {label: 'Paint', dutchstore:'verwinkel.nl, flexa.nl, Praxis, Gamma, Hornbach, littlegreene.com', note: "Well regarded brands include Sikkens, Flexa, Farrow & Ball, Histor"},
  {label: 'Party City', dutchstore:'Feestwinkel.nl', note: ""},
  {label: 'Safeway', dutchstore:'Albert Heijn, Jumbo, Lidl, Aldi, Dirk, Picnic, Spar', note: "Visit https://www.consumentenbond.nl/test/voeding-gezondheid/prijspeiling-huismerken and https://www.consumentenbond.nl/test/voeding-gezondheid/goedkoopste-supermarkt to compare supermarket prices"}, 
  {label: 'Sephora', dutchstore:'Douglas, ICI Paris XL', note: ""},
  {label: 'Target', dutchstore:'Hema', note: "Hema is a mini version of Target"},
  {label: 'TJMaxx', dutchstore:'TkMaxx', note: ""},
  {label: 'Walgreens', dutchstore:'Etos, Kruidvat', note: ""},
  {label: 'Whole Foods', dutchstore:'Landmarkt, EkoPlaza, Marqt, Odin', note: ""},
];

function ShowTableHeader(props) {
  if (props.show === true) {
    return (
      <TableRow
        key="usstore"
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          US Store Name
        </TableCell>
        <TableCell>Dutch Store Name</TableCell>
        <TableCell>Notes</TableCell>
      </TableRow>
    );
  } else {
    return null;
  }
}

function ShowAllStores(props) {
  if (props.show === true) {
    let ust = props.ent.label;
    let dst = props.ent.dutchstore;
    let note = props.ent.note;
    return (
      <TableRow
        key={ust}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          {ust}
        </TableCell>
        <TableCell>{dst}</TableCell>
        <TableCell>{note}</TableCell>
    </TableRow>
    );
  }
  return null;
}

function ShowStore({name, note}) {
  if (name === "") {
    return (
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          minWidth: 300,
          alignItems: "center",
          justifyContent: "center",
          padding: '10px',
          border: 1,
          borderColor: 'primary.main',
        }} 
      >
        <Typography 
          variant="body2"
        >
          Equivalent dutch store
          </Typography>
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          minWidth: 300,
          maxWidth: 800,
          alignItems: "center",
          justifyContent: "center",
          padding: '10px',
          border: 1,
          borderColor: 'primary.main',
        }}
      >
        <Stack justifyContent="center" alignItems="center" spacing={2}>
          <Typography variant='body2'>
            {name}
          </Typography>
          <Divider style={{width:'100%'}}/>
          <Typography variant='body2'>
            {note}
          </Typography>    
        </Stack>
      </Box>
    )
  }
}

const theme = createTheme();

const OrangeSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: orange[600],
    '&:hover': {
      backgroundColor: alpha(orange[600], theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: orange[600],
  },
}));

const useStyles = makeStyles(() => ({
  header: {
    "& .MuiTableCell-head": {
        color: "white",
        backgroundColor: "grey"
    },
  }
}));

export default function StoreMap() {

  const { header } = useStyles();

  const [dutchstorename, setDutchStoreName] = React.useState('');
  const [storenote, setDutchStoreNote] = React.useState('');

  const [checked, setChecked] = useState(false);

  const switchHandler = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg">
        <Helmet>
          <title>The Store Finder</title>
          <meta name="description" content="Find the Dutch equivalent of American stores" />
        </Helmet>
        <Header title="The American Burger's Store Finder" sections={sections} />
        <main>
        <Paper
          sx={{
            position: 'relative',
            p: { xs: 2, md: 2 },
            mb: 2,
          }}
        >
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item>
              <Typography 
                variant="body2"
                align="left"
              >
                What is the Dutch equivalent of Home Depot? Where can I find party supplies? Is Albert Heijn equivalent to Safeway? This store finder helps you to find the closest Dutch match to your favorite American stores.<br />
                Enter the name of a US store (e.g. Safeway) or a type of store (e.g. groceries) to find the Dutch equivalent. <br />
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSdevIYHHjCxdsuL51z4HUfLRRuuJTsHj-sOBWwi5fRA5friAw/viewform?hl=en" target='_blank' rel='noreferrer'>Click here to submit an entry</a> to the Store Finder.
              </Typography>
            </Grid>
            <Grid item md={12}>
              <Grid container spacing={2} direction={{xs:'column', md:'row'}} justifyContent="center" alignItems="center">
                <Grid item>
                  <Autocomplete
                    disablePortal
                    id="autocomplete-store-input"
                    sx = {{ m: 1, width: 300}}
                    options={USDutchStores}
                    renderOption={(props: any, option: any) => (
                      <Typography {...props} style={{ fontSize: 14 }}>{option.label}</Typography>
                    )}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, storename) => {
                      if (storename != null) {
                        setDutchStoreName(storename.dutchstore);
                        setDutchStoreNote(storename.note);
                      } else {
                        setDutchStoreName("");
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={<Typography variant="body2">Store Type or US Store Name</Typography>}
                        inputProps={{ ...params.inputProps, style: { fontSize: 14 } }}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6}>
                  <ShowStore 
                    name={dutchstorename}
                    note={storenote}/>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <FormControlLabel
                control={<OrangeSwitch checked={checked} onChange={switchHandler}/>}
                label="Show all stores"
              />
            </Grid>   
          </Grid>
          </Paper>
          <Grid container>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead className={header}>
                  <ShowTableHeader show={checked}/>
                </TableHead>
                <TableBody> 
                  {USDutchStores.map((entry) => (
                    <ShowAllStores
                      key={entry.label}
                      show = {checked}
                      ent = {entry}/>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid> 
          </Grid>
        </main>
      </Container>
      <Footer/>
    </ThemeProvider>
  );
}
