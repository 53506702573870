import * as React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  button: {
    backgroundColor: 'white',
    color: 'primary',
    '&:hover': {
      backgroundColor: 'white',
      color: 'primary',
  },
}})

function MainFeaturedPost(props) {
  const { post } = props;
  const classes = useStyles()

  return (
    <Paper
      sx={{
        position: 'relative',
        backgroundColor: 'grey.800',
        color: '#fff',
        mb: 4,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url(${post.image})`,
      }}
    >
      {/* Increase the priority of the hero background image */}
      {<img style={{ display: 'none' }} src={post.image} alt={post.imageText} />}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          background: 'linear-gradient(to right, rgba(0,0,0,1), rgba(0,0,0,0.3))',
        }}
      />
      <Grid container>
        <Grid item md={8}>
          <Box
            sx={{
              position: 'relative',
              p: { xs: 3, md: 6 },
              pr: { md: 0 },
            }}
          >
            {/*if color is set to inherit below, they inherit from 'Paper'. However
               once the drawer is opened in mobile view and then closed, the color of
               the text changes to black. Couldn't figure out why, so explicity setting
               color here.*/}
            <Typography component="h1" variant="h3" color="#fff" gutterBottom>
              {post.title}
            </Typography>
            <Typography variant="h5" color="#fff" paragraph>
              {post.description}
            </Typography>
            {post.linkTo ?
            <Button
              target="_blank"
              rel='noreferrer'
              size="small"
              className={classes.button}
              href={post.linkTo}
              key={post.linkText}
              sx={{ fontSize: 15 }}
            >
              {post.linkText}
            </Button>
            :<div></div>}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}

MainFeaturedPost.propTypes = {
  post: PropTypes.shape({
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    imageText: PropTypes.string.isRequired,
    linkText: PropTypes.string.isRequired,
    linkTo: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default MainFeaturedPost;
