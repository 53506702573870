import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from './Header';
import Footer from './Footer';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { sections } from './Blog';
import { Helmet } from "react-helmet";
import Paper from '@mui/material/Paper';

const filingstatus = [
  {
    value: 'm',
    label: 'Married, Filing Jointly',
  },
  {
    value: 's',
    label: 'Single',
  },
];

const yesno = [
  {
    value: 'y',
    label: 'Yes',
  },
  {
    value: 'n',
    label: 'No',
  },
];

/* US Federal income tax rates: Married, Filing Jointly (MFJ) */
const usFedMFJTaxBrackets =
        [[0, 20550, .10, 0],
         [20551, 83550, .12, 2055],
         [83551, 178150, .22, 9615],
         [178151, 340100, .24, 30427],
         [340101, 431900, .32, 69295],
         [431901, 647850, .35, 98671],
         [647851, 5000000, .37, 174253.5]];

/* US Federal income tax rates: Single */
const usFedSingleTaxBrackets =
        [[0, 10275, .10, 0],
         [10276, 41775, .12, 1027.50],
         [41776, 89075, .22, 4807.50],
         [89076, 170050, .24, 15213.50],
         [170051, 215950, .32, 34647.50],
         [215951, 539900, .35, 49335.50],
         [539901, 5000000, .37, 162718]];

/* US tax constants */
const usFedTaxConsts = {
        msd: 25900, /* MFJ standard deduction */
        ssd: 12550, /* Single standard deduction */
        ctc: 2000,  /* Child tax credit */
        medrate: .0145, /* Medicare tax rate below the income threshold */
        medaddrate: .009, /* Additional medicare tax rate above the income threshold */
        amtt_m: 250000, /* MFJ threshold above which additional medicare tax applies */
        amtt_s: 200000, /* Single threshold above which additional medicare tax applies */
        socsecrate: 0.0620, /* Social security tax rate */
        socseclimit: 147000, /* Limit of income subject to social security taxes */
        ctcl_m: 400000, /* Child tax credit income phase out limit MFJ */
        ctcl_s: 200000, /* Child tax credit income phase out limit Single */
};


const theme = createTheme();

function calcUSFedIncomeTax(income, filingStatus) {

  let fedTax = 0;

  let taxableIncome = Math.max(0, income - usFedTaxConsts[filingStatus+'sd']);

  var brackets;

  if (taxableIncome === 0) {
          return;
  }

  if (filingStatus === 'm') {
    brackets = usFedMFJTaxBrackets;
  } else {
    brackets = usFedSingleTaxBrackets;
  }

  for (var i = 0; i < brackets.length; i++) {
          if (taxableIncome > brackets[i][1]) {
                  continue;
          }
          fedTax = brackets[i][3];
          fedTax += ((taxableIncome - brackets[i][0]) * brackets[i][2]);
          break;
  }

  return fedTax;

}

function calcUSStateIncomeTax(income, taxRate) {
  return income * taxRate;
}

function calcUSSocSecurity(income) {
  income = Math.min(usFedTaxConsts['socseclimit'], income);
  return usFedTaxConsts['socsecrate'] * income;
} 

function calcUSMedicareTax(income, filingStatus) {
  let income_threshold = usFedTaxConsts['amtt_'+filingStatus];
  
  if (income <= income_threshold) {
    return income * usFedTaxConsts['medrate'];
  } else {
    let medTax = income_threshold * usFedTaxConsts['medrate'];
    medTax += (income - income_threshold) * (usFedTaxConsts['medrate'] + usFedTaxConsts['medaddrate']);
    return medTax;
  }
}

function calcUSChildTaxCredit(incomeA, incomeB, filingStatus, numchildren) {
  let maxInc = 0;
  let ctc = 0;

  if (numchildren === 0) {
    return ctc;
  }

  maxInc = usFedTaxConsts['ctcl_'+filingStatus];

  if (incomeA + incomeB < maxInc) {
    ctc = numchildren * usFedTaxConsts['ctc'];
  } else {
    /*
     * Above the phase out threshold the credit is reduced by $50 for every $1000 above the
     * threshold.
     */
    ctc = Math.max(0, (numchildren * usFedTaxConsts['ctc']) - (((incomeA + incomeB - maxInc)/1000) * 50));
  }
  return ctc;
}

/*
 * See https://www.svb.nl/en/child-benefit/.
 * The Dutch child benefit is paid per child, per quarter. We treat it 
 * as a tax credit for the purposes of this calculator.
 * The amounts vary based on age of child:
 *   0 - 5 years: 230.69
 *   6 - 11 years: 280.13
 *   12 - 17 years: 329.56
 * I don't want to take in the age of every child, so I'm going to use
 * an average amount here.
 */
function calcKinderbijslag(numchildren) {
  return ((230.69 + 280.13 + 329.56)/3) * numchildren * 4;
}

const dutchBox1Brackets =
        [[0, 69399, .3707, 0],
         [69400, 5000000, .4950, 25726.21]];

function calcDutchBox1Tax(income) {
  let box1Tax = 0;

  var brackets = dutchBox1Brackets;
  
  for (var i = 0; i < brackets.length; i++) {
    if (income > brackets[i][1]) {
            continue;
    }
    box1Tax = brackets[i][3];
    box1Tax += ((income - brackets[i][0]) * brackets[i][2]);
    break;
  }
  return box1Tax;
}

/*
 * [start income, end income, rate, cumulative credit of previous slabs, cap on arbeidskorting]
 * See https://www.belastingdienst.nl/wps/wcm/connect/bldcontentnl/belastingdienst/prive/inkomstenbelasting/heffingskortingen_boxen_tarieven/heffingskortingen/arbeidskorting/tabel-arbeidskorting-2022
 * for details
 */
let arbeidskortingBrackets =
        [[0, 10351, .04541, 0, 0],
         [10351, 22357, 0.28461, 470, 0],
         [22357, 36650, 0.0261, 3887, 0],
         [36650, 109347, 0.0586, 0, 4260],
         [109347, 5000000, 0, 0, 0]];

/*
 * This is the employee tax credit aka Arbeidskorting
 */
function calcArbeidskorting(income) {
  let akCredit = 0;
  var brackets = arbeidskortingBrackets;
  
  for (var i = 0; i < brackets.length; i++) {
    if (income > brackets[i][1]) {
            continue;
    }
    akCredit = brackets[i][3];
    akCredit += ((income - brackets[i][0]) * brackets[i][2]);
    if (brackets[i][4] !== 0) {
      akCredit = brackets[i][4] - akCredit;
    }
    break;
  }
  return akCredit;
}

/*
 * This is the Inkomensafhankelijke combinatiekorting aka the income-dependent
 * combination tax credit.
 * https://www.belastingdienst.nl/wps/wcm/connect/bldcontentnl/belastingdienst/prive/inkomstenbelasting/heffingskortingen_boxen_tarieven/heffingskortingen/inkomensafhankelijke_combikorting/inkomensafhankelijke_combinatiekorting
 */
function calcInkCombkorting(income, undertwelve) {
  if (undertwelve === "n") {
    return 0;
  }
  if (income <= 5220) {
    return 0;
  }
  if (income <= 27350) {
    return (0.1145 * (income - 5220));
  } else {
    return 2534;
  }
}
/*
 * This is the general tax credit aka AlgemeneHeffingskorting.
 * See https://www.belastingdienst.nl/wps/wcm/connect/bldcontentnl/belastingdienst/prive/inkomstenbelasting/heffingskortingen_boxen_tarieven/heffingskortingen/algemene_heffingskorting/tabel-algemene-heffingskorting-2022 
 */
const maxAlgHefKort = 2888;
const AlgHefKortBracket1 = 21318;
const AlgHefKortBracket2 = 69399;

function calcAlgemeneHeffingskorting(income) {
  if (income < AlgHefKortBracket1) {
    return maxAlgHefKort;
  }
  if (income < AlgHefKortBracket2) {
    return (maxAlgHefKort - 0.06007 * (income - AlgHefKortBracket1));
  } else {
    return 0;
  }
}

/*
 * Calculate box1 taxes owed.
 */
function calcDutchIncomeTax(incomeA, incomeB, thirtypercent, undertwelve, numchildren) {
  let box1TaxA = 0;
  let ahkCreditA = 0;
  let box1TaxB = 0;
  let ahkCreditB = 0;
  let inkCombCredit = 0;
  let akCreditA = 0;
  let akCreditB = 0;

  incomeA = Math.max(incomeA, incomeB);
  incomeB = Math.min(incomeA, incomeB);

  if (thirtypercent === "y") {
    incomeA = 0.7 * incomeA;
    incomeB = 0.7 * incomeB;
  }

  box1TaxA = calcDutchBox1Tax(incomeA);
  akCreditA = calcArbeidskorting(incomeA);
  ahkCreditA = calcAlgemeneHeffingskorting(incomeA);
  
  box1TaxB = calcDutchBox1Tax(incomeB);
  akCreditB = calcArbeidskorting(incomeB);
  ahkCreditB = calcAlgemeneHeffingskorting(incomeB);

  // Calculate the income-dependent combination tax credit for the partner with
  // the lower income.
  inkCombCredit = calcInkCombkorting(incomeB, undertwelve);

  // For the higher earning partner, cap the general tax credit to income tax owed.
  ahkCreditA = Math.min(box1TaxA - akCreditA, ahkCreditA);
 
  // For the lower earning partner, cap the credits to income tax owed.
  inkCombCredit = Math.min(box1TaxB - akCreditB, inkCombCredit);
  ahkCreditB = Math.min(box1TaxB - akCreditB - inkCombCredit, ahkCreditB);

  console.log(box1TaxA, box1TaxB, akCreditA, akCreditB, ahkCreditA, ahkCreditB, inkCombCredit);

  let kbs = calcKinderbijslag(numchildren);

  return (Math.max(0, box1TaxA + box1TaxB - ahkCreditA - ahkCreditB  - akCreditA - akCreditB - inkCombCredit - kbs));
}

function CalcUSTaxes(incomeA, incomeB, filingStatus, stateTaxRate, numchildren) {
  let fedTax = calcUSFedIncomeTax(incomeA + incomeB, filingStatus);
  let stateRate = stateTaxRate/100;
  let stateTax = calcUSStateIncomeTax(incomeA + incomeB, stateRate);
  let socSecA = calcUSSocSecurity(incomeA);
  let socSecB = calcUSSocSecurity(incomeB);
  let medTax = calcUSMedicareTax(incomeA + incomeB, filingStatus);
  let ctc = calcUSChildTaxCredit(incomeA, incomeB, filingStatus, numchildren);

  return fedTax + stateTax + socSecA + socSecB + medTax - ctc;
}

const usdtoeur = 0.9;
const eurtousd = 1.12;

function CalcTaxes(fs, incomeA, incomeB, thirtypercent, undertwelve, statetaxrate, numchildren) {
  let iA = Number(incomeA);
  let iB = Number(incomeB);
  let iADutch = iA * usdtoeur;
  let iBDutch = iB * usdtoeur;

  let dt = calcDutchIncomeTax(iADutch, iBDutch, thirtypercent, undertwelve, Number(numchildren));
  dt = dt * eurtousd;
  let ust = CalcUSTaxes(iA, iB, fs, Number(statetaxrate), Number(numchildren));

  let detr = (dt * 100)/(iA + iB);
  let usetr = (ust * 100)/(iA + iB);
 
  detr = detr.toFixed(2);
  usetr = usetr.toFixed(2);

  return [detr, usetr];
}

export default function EffectiveTaxCalculator() {
  let fedTaxRate = 0;
  let dutchTaxRate = 0;

  const [nopartner, setPartner] = React.useState(false);

  const [fs, setFilingStatus] = React.useState('m');

  const handleFSChange = (event) => {
    setFilingStatus(event.target.value);
    if (event.target.value === 's') {
      setPartner(true);
    } else {
      setPartner(false);
    }
  };

  const [thirtypercent, setThirtyPercentStatus] = React.useState('n');

  const handleThirtyPercentChange = (event) => {
    setThirtyPercentStatus(event.target.value);
  };

  const [undertwelve, setUnderTwelveStatus] = React.useState('n');

  const handleUnderTwelveChange = (event) => {
    setUnderTwelveStatus(event.target.value);
  };

  const [incomeA, setIncomeA] = React.useState(0);

  const handleIncomeAChange = (event) => {
    setIncomeA(event.target.value);
  };

  const [incomeB, setIncomeB] = React.useState(0);

  const handleIncomeBChange = (event) => {
    setIncomeB(event.target.value);
  };

  const [statetaxrate, setStateTaxRate] = React.useState(0);

  const handleStateTaxRateChange = (event) => {
    setStateTaxRate(event.target.value);
  };

  const [numchildren, setNumChildren] = React.useState(0);

  const handleNumChildrenChange = (event) => {
    setNumChildren(event.target.value);
  };

  const [ustaxrate, setUSTaxRate] = React.useState(0);

  const [dutchtaxrate, setDutchTaxRate] = React.useState(0);

  const etr = " effective tax rate ";
  const clickhere = "Click here ";
  const casestudy = "Visit the case study page "

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg">
        <Helmet>
          <title>The Effective Tax Comparison Calculator</title>
          <meta name="description" content="A calculator to compare effective tax rates in the US and the Netherlands" />
        </Helmet>
        <Header title="The American Burger's Effective Tax Calculator" sections={sections} />
        <main>
          <Paper
            sx={{
              position: 'relative',
              p: { xs: 2, md: 4 },
              mb: 2,
            }}
          >
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
              }}
              noValidate
              autoComplete="off"
            >
            <div align="left">
              <Typography variant="body2" paragraph>
                This calculator gives you a way to compare U.S. and Dutch taxation by computing the
                  <Link variant="body2" href="/glossary#effectivetaxrate">
                    {etr}
                  </Link>
                in both countries. If you made $X living and working in the U.S., how does that compare
                to making the equivalent &euro;Y living and working in the Netherlands?<br /><br />
                The effective tax calculator accepts as input your income, applies the 
                appropriate deductions and tax credits and calculates how much tax you owe.<br />
                <Link variant="body2" href="/taxes/effectivetaxcalculator/etrcalculatorexplained">
                    {clickhere}
                </Link> 
                for help with calculator input fields or to understand how the calculator works.<br />
                Disclaimer: I am not a tax professional. This calculator is not intended to serve as 
                an accurate estimation of tax owed.<br />
                <Link variant="body2" href="/taxes/effectivetaxcalculator/etrcalculatorexamples">
                  {casestudy}
                </Link>
                to see how compare effective tax rates for a few common scenarios.
              </Typography>
            </div>
            <div align="center">
              <Typography variant="subtitle1" paragraph>
                USA information
              </Typography>
              <TextField
                id="outlined-filing-status"
                select
                label="Filing Status"
                value={fs}
                onChange={handleFSChange}
              >
                {filingstatus.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="outlined-state-income-tax"
                label="State income tax rate"
                type="number"
                value={statetaxrate}
                onChange={handleStateTaxRateChange}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
              /> 
            </div> 
            <div align="center">
              <Typography variant="subtitle1" paragraph>
                Dutch information
              </Typography>
              <TextField
                id="outlined-filing-status"
                select
                label="30% Ruling"
                value={thirtypercent}
                onChange={handleThirtyPercentChange}
              >
                {yesno.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="outlined-filing-status"
                select
                label="Children aged 12 and under"
                value={undertwelve}
                onChange={handleUnderTwelveChange}
              >
                {yesno.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div align="center">
              <Typography variant="subtitle1" paragraph>
                Common information
              </Typography>
              <TextField
                required
                id="outlined-number"
                label="Partner A income"
                type="number"
                value={incomeA}
                onChange={handleIncomeAChange}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
              />
              <TextField
                id="outlined-number"
                label="Partner B income"
                disabled={nopartner}
                type="number"
                value={incomeB}
                onChange={handleIncomeBChange}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
              />
              <TextField
                id="outlined-number"
                label="Number of dependent children"
                type="number"
                value={numchildren}
                onChange={handleNumChildrenChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              </div>
              <div align="center">
              <Button 
                variant="contained"
                onClick={() => {
                  [dutchTaxRate, fedTaxRate] = CalcTaxes(fs, incomeA, incomeB, thirtypercent, undertwelve, statetaxrate, numchildren);
                  setUSTaxRate(fedTaxRate);
                  setDutchTaxRate(dutchTaxRate);
                }}
              >
                Calculate
              </Button>
              </div>
              <div align="center">
              <Typography variant="subtitle1" paragraph>
                <br />Results
              </Typography>
                <TextField
                  id="us-effective-tax-rate"
                  label="Effective tax rate USA"
                  type="number"
                  value={ustaxrate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                />
                <TextField
                  id="dutch-effective-tax-rate"
                  label="Effective tax rate Netherlands"
                  type="number"
                  value={dutchtaxrate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                />
                <br />
                <br />
              </div>
              <div>
                <Typography
                  variant="subtitle1"
                  align="left"
                >
                  Known limitations of this calculator
                </Typography>
                <Typography component="div" variant="body2">
                  <ul>
                    <li>The calculator only computes tax on income earned as a wage from employment.</li>
                    <li>The U.S. filing status of 'Married, filing separately', 'Head of household' and 'Qualifying widower' are not supported.</li>
                    <li>If you choose 'Married, Filing Jointly' the calculator assumes both partners are U.S. citizens.</li>
                    <li>Itemized deductions are not supported - the calculator uses the standard deduction.</li>
                    <li>USA states have widely different tax regimes ranging from no income tax, to having multiple brackets, to flat rates. For simplicity this calculator uses a flat tax rate only. For the same reason, standard deduction is not applied for US state tax calculations.</li>
                    <li>The calculator uses a child tax credit amount of $2000 per child instead of the temporarily increased amount of this credit.</li>
                    <li>Dutch box1 taxes have different rates if you are of retirement age. This calculator doesn't take this into account.</li>
                    <li>The kinderbijslag (Dutch child allowance) varies based on age of the child. This calculator uses an average.</li>
                    <li>If '30% ruling' is set to true, we assume that it applies to the income of both partners.</li>
                  </ul>
                </Typography>
              </div>
            </Box>   
          </Paper>
        </main>
      </Container>
      <Footer/>
    </ThemeProvider>
  );
}
