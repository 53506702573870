import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from './Header';
import Footer from './Footer';
import Typography from '@mui/material/Typography';
import { sections } from './Blog';
import { Helmet } from "react-helmet";
import Paper from '@mui/material/Paper';

const theme = createTheme();

export default function Recommendations() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg">
        <Helmet>
          <title>My recommendations</title>
          <meta name="description" content="American Burger's recommendations for services and products in the Netherlands" />
        </Helmet>
        <Header title="My Recommendations" sections={sections} />
        <Paper
          sx={{
            position: 'relative',
            p: { xs: 2, md: 2 },
            mb: 2,
          }}
        >
        <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
          Children's Stuff
        </Typography>
        <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
          English Language Books
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          <a href="/english-books-kids-netherlands#jojobelle-childrens-books" target="_blank">JojoBelle's Book Store</a>
        </Typography>
        <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
          Travel
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          <a href="https://www.seat61.com/" target="_nofollow">seat61.com</a> is a fantastic resource for train travel in Europe. 
        </Typography>
        </Paper>
      </Container>
      <Footer/>
    </ThemeProvider>
  );
}
