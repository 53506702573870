import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from './Header';
import FeaturedPost from './FeaturedPost';
import Footer from './Footer';
import { sections } from './Blog';
import { Helmet } from "react-helmet";
  
const imageAttribution = [
  {
    bylink: "https://unsplash.com/@humakabakci?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText",
    by: "Huma Kabakci",
    from: "Unsplash",
    img_name: 'coming_soon.jpg',
  },
  {
    bylink: "https://unsplash.com/@adventureregistry?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText",
    by: "Alex Perz",
    from: "Unsplash",
    img_name: 'mailbox.jpg',
  },
  {
    bylink: "https://unsplash.com/@ibrahimboran?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText",
    by: "Ibrahim Boran",
    from: "Unsplash",
    img_name: "dollars_euros_on_table.jpg",
  },
];

const featuredPosts = [
  {
    title: 'Virtual Mailboxes',
    description:
      'How to keep track of mail that you receive in the US',
    image: 'mailbox.jpg',
    imageLabel: 'a mailbox',
    to: '/expattools/virtualmailboxes',
    extlink: false,
  },
  {
    title: 'All about VPNs',
    description:
      'Why you need a VPN and how to choose one',
    image: 'coming_soon.jpg',
    imageLabel: 'coming soon',
    extlink: false,
  },
  {
    title: 'Money Transfers',
    description:
      'A step-by-step guide to transfering money from the U.S. to the Netherlands',
    image: 'dollars_euros_on_table.jpg',
    imageLabel: 'dollars and euros spread on a table',
    to: '/expattools/cheapest-money-transfer-us-netherlands',
    extlink: false,
  },
  {
    title: 'Google Voice',
    description:
      'Why and How to port your US number to Google Voice',
    image: 'coming_soon.jpg',
    imageLabel: 'coming soon',
    extlink: false,
  },
];

const theme = createTheme();

export default function ExpatTools() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg">
        <Helmet>
          <title>Expat Tools</title>
          <meta name="description" content="Essential tools and tricks for an expat in the Netherlands" />
        </Helmet>
        <Header title="The American Burger's Guide to Useful Tools for Expats" sections={sections} />
        <main>
          <Grid container spacing={4}>
            {featuredPosts.map((post) => (
              <FeaturedPost key={post.title} post={post} />
            ))}
          </Grid>
        </main>
      </Container>
      <Footer
        imageattr={imageAttribution}
      />
    </ThemeProvider>
  );
}
