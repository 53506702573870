import * as React from 'react';
import ReactMarkdown from 'react-markdown'
import rhid from "remark-heading-id";
import rehypeSlug from 'rehype-slug'
import rehypeAutolinkHeadings from 'rehype-autolink-headings'

const myContent = {
  type: 'element',
  tagName: 'span',
  properties: {className: ['fa', 'fa-link', 'fa-2xs'], style: "padding-left: 0.5em;"},
  children: []
}

const headings = {
  allheadings: ['h1', 'h2', 'h3'],
  h1headings: ['h1'],
}
            
export default function Markdown(props) {
  const {noheadings, htype} = props;
  const h = String(htype);

  if (noheadings === true) {
    return <ReactMarkdown 
    remarkPlugins={[rhid]}
    children={props.children}
    components={{
      a: ({ node, children, ...props}) => {
        const linkProps = props;
        if (props.href.match('http')) {
          linkProps['rel'] = 'noopener noreferrer'
          linkProps.target = '_blank';
          return <a {...linkProps}>{children}</a>
        }
        return <a {...props}>{children}</a>
      },
      img:({node,...prps})=><img alt="" style={{maxWidth:'100%'}}{...prps}/>}} />;
  }

  return <ReactMarkdown 
          remarkPlugins={[rhid]}
          rehypePlugins={[rehypeSlug, [rehypeAutolinkHeadings, {behavior: 'append', content: myContent, test: headings[h]}]]}
          children={props.children}
          components={{
            a: ({ node, children, ...props}) => {
              const linkProps = props;
              if (props.href.match('http')) {
                linkProps['rel'] = 'noopener noreferrer'
                linkProps.target = '_blank';
                return <a {...linkProps}>{children}</a>
              }
              return <a {...props}>{children}</a>
            },
            img:({node,...prps})=><img alt="" style={{maxWidth:'100%'}}{...prps}/>}} />;
}