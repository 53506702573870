import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from './Header';
import Footer from './Footer';
import Typography from '@mui/material/Typography';
import { sections } from './Blog';
import { Helmet } from "react-helmet";
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { MailchimpForm } from './Sidebar';

const theme = createTheme();

export default function NewOldBox3() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg">
        <Helmet>
          <title>Comparing old vs. new box 3 taxation systems in the Netherlands</title>
          <meta name="description" content="Comparing the existing box 3 taxation system to the proposed new system (based on vermogensaanwasbelasting)" />
        </Helmet>
        <Header title="Box 3 Old vs. New Taxation Systems: A Comparison" sections={sections} />
        <Paper
          sx={{
            position: 'relative',
            p: { xs: 2, md: 2 },
            mb: 2,
          }}
        >
          <Typography variant="body2" component="div" gutterBottom>
            Unlike the U.S. the Netherlands taxes your wealth under a system called box 3 taxation. If you aren’t familiar with 
            how this system works, you should start by reading <a href="/taxes/dutchtaxationbasics#box3-taxation-aka-the-wealth-tax">my writeup about the box 3 system</a>. <br /><br />
            In December 2021 the Dutch supreme court ruled that <a href="https://www2.deloitte.com/nl/nl/pages/tax/articles/2017-and-2018-box-3-levy-violates-property-rights-and-prohibition-of-discrimination.html" target="_nofollow">the way returns are currently taxed in box 3 violates property rights</a>. The
            Dutch government set to work on a replacement system, and most of us have anticipated the arrival of  the new box 3 system
            with a sense of foreboding, convinced that it will most definitely be bad news. <br /><br />

            The Dutch government has made an <a href="https://www.rijksoverheid.nl/documenten/kamerstukken/2022/04/15/kamerbrief-over-contourennota-voor-box-3-heffing-op-basis-van-werkelijk-rendement" target="_nofollow">initial bare-bones proposal for a replacement box 3 taxation system</a>.<br /><br /> 

            In today’s post we are going to compare the old and new box 3 taxation systems and attempt to answer the question: is the new taxation system more onerous than the existing one? Was our pessimism justified or were our misgivings misplaced? 

          </Typography>
          <Typography variant="h5" gutterBottom>
            A summary of the old (a.k.a. existing) box 3 system
          </Typography>
          <Typography variant="body2" gutterBottom>
            Under the current system of box 3 taxation, your portfolio is assumed to make a certain return every year. This 
            imaginary percentage of return is determined by the belastingdienst (a.k.a. the Dutch IRS) and bears no relation to 
            reality. There are various brackets - and the amount of the imaginary return depends on the bracket into which your 
            portfolio falls. The return assumed in the highest bracket in 2022 is 5.53%.These imaginary rates are used to calculate
            an imaginary gain and you are taxed at the rate of 31% on these imaginary gains. <br /><br />

            If you would like to take a closer look at the existing system in all its glory, we have a <a href="/taxes/box-3-tax-calc-2022">box 3 tax calculator</a> with
            a ‘show me the math’ option. Open the kimono and enjoy the full monty!<br /><br />

            The key word for the existing system is imaginary - the amount you are taxed has no relation to real world gains or 
            losses. This means that if the stock market is up 20% you are still taxed as if it only went up 5.53% (or less, if 
            you are in a lower bracket!). Conversely, if the stock market loses 10%, you are still taxed as if it went up by 
            5.53%.<br /><br />
            
            One good thing about the existing box 3 system is that the imaginary rates allow for predictability - you can
            easily model the effect of box 3 taxation on your portfolio and plan accordingly.
          </Typography>
          <Typography variant="h5" gutterBottom>What is the new box 3 system?</Typography>
          <Typography variant="body2" gutterBottom>
            In the new proposed box 3 system we are going to be taxed on both actual and unrealized gains, instead of imaginary ones. The
            Dutch term for this is *vermogensaanwasbelasting*<br /><br />

            The plan that has been proposed is: in box 3 the capital gains of your portfolio will be taxed on an annual basis. You 
            will be taxed whether or not you actually realize your gains.<br /><br />

            An example:<br />
            On January 1st your portfolio is worth €200,000. Your investments do well and on December 31st your portfolio is worth 
            €214,000. Your portfolio has seen a capital gain of €14,000. This is your unrealized (i.e. you have sold nothing; all 
            your gains are on paper) return for the year. You will owe box 3 taxes on these gains.<br /><br />

            Of course, this was the simplest possible example. In real life you may have acquired more shares/units of ETFs 
            during the year, you may have sold a part of your portfolio etc., and those transactions will be taken into account.<br /><br />

            At a glance, it is obvious that you could end up paying much more in taxes under this scheme - because you are paying 
            taxes based on the actual performance of your portfolio. A possible advantage of the new scheme is that they state 
            that some provision will be made to allow you to offset your (possibly unrealized) gains with your (also 
            possibly unrealized) losses .<br /><br />

            What this means is that if in year 1 you have unrealized losses of say €10,000, and in year 2 you have an unrealized 
            gain of €15,000, then in year 2 you should only owe taxes on €5000 because you carried forward your losses.<br /><br /> 

            They have not yet clarified the rules of carrying over losses (e.g. how long will you be allowed to carry forward 
            a loss?). Many other details of the new proposal are also unknown - e.g. what will the tax rate be? Will there be a single
            rate or progressive rates?

          </Typography>
          <Typography variant="h5" gutterBottom>Assumptions</Typography>
          <Typography variant="body2" component="div">
            The new box 3 system proposed by the government is - once it is debated, modified and codified into law - slated to go into 
            effect starting in 2025. The only <a href="https://www.rijksoverheid.nl/documenten/kamerstukken/2022/04/15/kamerbrief-over-contourennota-voor-box-3-heffing-op-basis-van-werkelijk-rendement" target="_nofollow">proposal we have seen</a> so
            far is a skeleton. So in order to compare it with the fully fleshed out existing box 3 system it is going to be necessary 
            to make some assumptions. 
            <ol>
              <li>We assume that the box 3 tax rate remains the same as it currently is: 31%.</li>
              <li>The draft proposal states that the current tax-free allowance (€50,650 per fiscal partner in 2022) will be 
                replaced by a tax-free return amount. We will assume that you can make a gain of €2801 (per fiscal partner) 
                tax-free. We multiplied the current tax-free allowance of the old system with the current imaginary return value 
                to get this number.</li>
              <li>For simplicity, we assume that losses are carried over to cancel out future gains until they are depleted. It is unlikely
                that this will end up being the case, but as you will see below this is not an overly optimistic assumption for the 
                purposes of this post, because in the cases that I studied, the loss was never carried for more than 7 years before it was depleted.</li>
              <li>For simplicity, we assume the USD:EUR conversion rate is 1:1.</li>
            </ol>
          </Typography>
          <Typography variant="h5" gutterBottom>Comparing the new box 3 system to the old box 3 system</Typography>
          <Typography variant="body2" component="div" gutterBottom>
            In order to compare the two systems we will:
            <ul>
              <li>Assume a starting portfolio value.</li>
              <li>Assume a period of time (typically 60 years).</li>
              <li>Model the growth of the portfolio for 60 years assuming that each year we pay box 3 taxes.</li>
              <li>At the end of the 60 year period we compare the values of the portfolio under both the new and the old box 3 
                taxation systems and then declare a winner.</li>
            </ul>
            There are various ways in which we can model portfolio growth, and each of these will be its own case study.<br /><br />

            <b>SPOILER ALERT</b>: If you have your money on one of these horses, you are in for a surprise. Neither tax scheme 
            won every case study!<br /><br />

            Let the games begin!
          </Typography>
          <Typography variant="h6" gutterBottom>Case study 1: The Baseline - Only Gains</Typography>
          <Typography variant="body2" gutterBottom>
            We assume the market returns an average of 7% (which just happens to be the historical average of the S&P 500, 
            adjusted for inflation) over our simulation period of 60 years.
            </Typography>
          <Stack alignItems="center" sx={{ mt: 1, mb: 1 }}>
            <Box 
              component="img"
              sx={{ position:'relative', width: {xs: 300, md: 300, lg:340} }}
              src="/new_vs_old_box3_case_study_1_input.png"
              alt="Case study 1 input parameters"
            />
          </Stack> 
          <Typography variant="h7" gutterBottom>Result</Typography>
          <Typography variant="body2" component="div" gutterBottom>
            No surprises here - round one goes to the old box 3 system! The highest (imaginary) return we are taxed on is 5.53% 
            while the actual return is 7%. The new scheme is at a clear disadvantage here.<br />
            The old box 3 system yields a portfolio that is 14% larger than under the new box 3 system.
          </Typography>
          <Stack alignItems="center" sx={{ mt: 1, mb: 1 }}>
            <Box 
              component="img"
              sx={{ position:'relative', width: {xs: 300, md: 600, lg:640} }}
              src="/new_vs_old_case_study_1_results.png"
              alt="Case study 1 portfolio growth comparison"
            />
          </Stack>
          <Typography variant="h6" gutterBottom>Case Study 2: Let’s throw some losses in there</Typography>
          <Typography variant="body2" component="div" gutterBottom>
            What happens if we throw some losses in there? This case study looks at the effect of throwing in a year of losses 
            after every 5 years of gains. The rate of return, positive or negative, is 7%.
          </Typography>
          <Stack alignItems="center" sx={{ mt: 1, mb: 1 }}>
            <Box 
              component="img"
              sx={{ position:'relative', width: {xs: 300, md: 300, lg:340} }}
              src="/new_vs_old_box3_case_study_2_input.png"
              alt="Case study 2 input parameters"
            />
            <div>
              <Typography variant="body2">The newsletter links to the excel sheet used to generate these results. Sign up here to get access!</Typography>
              <MailchimpForm />
            </div>
          </Stack> 
          <Typography variant="h7" gutterBottom>Result</Typography>
          <Typography variant="body2" component="div" gutterBottom>
            The new box 3 system wins! After 60 years of gains and losses, the new box 3 system yields a portfolio that is 
            9% larger than the one we end up with under the old system.
          </Typography>
          <Stack alignItems="center" sx={{ mt: 1, mb: 1 }}>
            <Box 
              component="img"
              sx={{ position:'relative', width: {xs: 300, md: 600, lg:640} }}
              src="/new_vs_old_case_study_2_results.png"
              alt="Case study 2 portfolio growth comparison"
            />
          </Stack>
          <Typography variant="body2" component="div" gutterBottom>
            We see the effect of carrying over our losses coming into play here. Loss carry-over gives us periodic respites 
            from box 3 taxation, resulting in a larger portfolio.
          </Typography>
          <Typography variant="h6" gutterBottom>Case 3: Gain:Loss ratio mimicking the S&P 500</Typography>
          <Typography variant="body2" component="div" gutterBottom>
            What if instead of choosing an arbitrary gain:loss ratio of 5:1, we attempt to mimic the pattern actually seen in
            historical returns? If we look at annual historical S&P 500 returns from 1928 to YTD 2022, the ratio of years with 
            gains:losses is 2:1, so we are going to try that ratio next.
          </Typography>
          <Stack alignItems="center" sx={{ mt: 1, mb: 1 }}>
            <Box 
              component="img"
              sx={{ position:'relative', width: {xs: 300, md: 300, lg:340} }}
              src="/new_vs_old_box3_case_study_3_input.png"
              alt="Case study 3 input parameters"
            />
          </Stack> 
          <Typography variant="h7" gutterBottom>Result</Typography>
          <Typography variant="body2" component="div" gutterBottom>
            The winner again, in the yellow corner, the challenger, the upstart - the new box 3 taxation system!<br />
            The new system yields a portfolio that is a whopping 28% larger than the old scheme.
          </Typography>
          <Stack alignItems="center" sx={{ mt: 1, mb: 1 }}>
            <Box 
              component="img"
              sx={{ position:'relative', width: {xs: 300, md: 600, lg:640} }}
              src="/new_vs_old_case_study_3_results.png"
              alt="Case study 3 portfolio growth comparison"
            />
          </Stack>
          <Typography variant="h6" gutterBottom>Case Study 4: Actual historical returns 1928 - 1987</Typography>
          <Typography variant="body2" component="div" gutterBottom>
            Chuffed by our success, we turn our attention to actual historical returns.
          </Typography>
          <Stack alignItems="center" sx={{ mt: 1, mb: 1 }}>
            <Box 
              component="img"
              sx={{ position:'relative', width: {xs: 300, md: 300, lg:340} }}
              src="/new_vs_old_box3_case_study_4_input.png"
              alt="Case study 4 input parameters"
            />
          </Stack> 
          <Typography variant="body2" component="div" gutterBottom>
            We will run our simulation for the 60 year period from 1928 to 1987, using the actual historical returns from this 
            period (so the 7% input parameter seen above will be ignored by the simulation). 
          </Typography>
          <Typography variant="h7" gutterBottom>Result</Typography>
          <Typography variant="body2" component="div" gutterBottom>
            The old box 3 system wins, barely. It results in a portfolio that is 2% larger than under the new system.
          </Typography>
          <Stack alignItems="center" sx={{ mt: 1, mb: 1 }}>
            <Box 
              component="img"
              sx={{ position:'relative', width: {xs: 300, md: 600, lg:640} }}
              src="/new_vs_old_case_study_4_results.png"
              alt="Case study 4 portfolio growth comparison"
            />
          </Stack>
          <Typography variant="body2" component="div" gutterBottom>
            This graph is _so_ much more interesting than the previous ones. Let’s dig in. First of all, even though the ratio 
            of gain years:loss years is still 2:1, the new box 3 tax scheme does not perform as well as it did in case study 3. 
            Why not? Because timing and starting portfolio value matter.<br /><br /> 

            There is a sharp downward curve at the start of the portfolio graph representing 4 years of negative returns from 
            1929 - 1932 (including a whopping -47% in 1931). Under the old box 3 scheme the effect of these years at the start 
            of the simulation is to deplete the portfolio enough that the portfolio slipped into the lowest bracket where the 
            imaginary return is a mere 1.15%! These early years of very low taxation made the old system behave in a way that 
            is similar to the new system with its years of 0 taxes due to loss carry over. This allowed the old scheme to 
            ‘catch up’ with the new one in a way that was not possible in case study 3.<br /><br /> 

            For those who are curious I will note that if you start with even a slightly larger portfolio (e.g. $220,000), this 
            case study results in a draw between the two systems. With an even larger starting portfolio (e.g. $500,000), the new 
            box 3 system handily beat the old one.<br /><br />

            So the order of gains and losses (also called the sequence of returns) has a big impact on the portfolio 
            performance. On to the next case study!
          </Typography>
          <Typography variant="h6" gutterBottom>Case Study 5: Actual historical returns 1963 - YTD 2022</Typography>
          <Typography variant="body2" component="div" gutterBottom>
            For our final case study today we are going to run our portfolio through historical returns again, but of the 
            60 most recent years 1963 - 2022.
          </Typography>
          <Stack alignItems="center" sx={{ mt: 1, mb: 1 }}>
            <Box 
              component="img"
              sx={{ position:'relative', width: {xs: 300, md: 300, lg:340} }}
              src="/new_vs_old_box3_case_study_5_input.png"
              alt="Case study 5 input parameters"
            />
          </Stack>
          <Typography variant="h7" gutterBottom>Result</Typography>
          <Typography variant="body2" component="div" gutterBottom>
            A resounding win for the old box 3 system.<br />
            The old box 3 system yields a portfolio that is 21% larger than the new system.
          </Typography>
          <Stack alignItems="center" sx={{ mt: 1, mb: 1 }}>
            <Box 
              component="img"
              sx={{ position:'relative', width: {xs: 300, md: 600, lg:640} }}
              src="/new_vs_old_case_study_5_results.png"
              alt="Case study 5 portfolio growth comparison"
            />
          </Stack>
          <Typography variant="body2" component="div" gutterBottom>
            Why did the new system take a beating? In this time period the ratio of years with gains:losses is very nearly 3:1. Not 
            only are there more gains - again, the timing matters. At the start of the simulation the two portfolios track each 
            other closely. Then you see a spike in the graph from years 33 - 37. These were 5 years of double digit gains that 
            were not preceded by a period of significant loss. So under the new system we had very large tax bills during this 
            period, allowing the portfolio under the old system to grow significantly larger, and the portfolio under the new 
            system never manages to catch up.
          </Typography>
          <Typography variant="h5" gutterBottom sx={{ mt:2, mb:2 }}>Customizing the Case Studies</Typography>
          <Typography variant="body2">
            I created a spreadsheet that I used to generate the results in this post. The spreadsheet allows you to modify
            the input parameters like portfolio size, rate of return etc. 
            The American Burger newsletter links to this spreadsheet, allowing you to perform your own portfolio experiments. Sign up
            here to get access!
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              maxWidth: 800,
            }}
          >
            <MailchimpForm />
          </Box>  
            <Typography variant="h5" gutterBottom sx={{ mt:2, mb:2 }}>Conclusion</Typography>
            <Typography variant="body2" gutterBottom >
              I confess to being one of those who had my money on the new scheme being a perpetual loser, so I was pleasantly 
              surprised that this was not the case. I must, of course, remind you that these results were all based on the 
              assumptions we made. If the tax rate ends up being higher than 31% or the tax free allowance much lower, or the loss
              carryover limited to a couple of years, the new tax scheme will obviously not perform as well as it has in this post.
              Though the details will change some principles remain.<br /><br /> 

              If you are invested in the stock market, the performance of the new box 3 system is going to depend on the sequence 
              of returns that we see for the next few decades.<br /> 
              If we have the ‘usual’ ratio of gains:losses, the new box 3 system will stand a chance of costing about the 
              same as the old system.<br /> 
              If we happen to live through a period of abysmal returns we can comfort ourselves with the fact that thanks to 
              the new system we have paid less taxes than we would have under the old system.<br />
              If we are fortunate enough to live through a charging bull market, we will pay a ton more in taxes, but we can 
              wipe our tears with our currency while doing our Scrooge impersonation in the money pit of our large portfolio.<br /><br />

              All in all, there does not at this time seem to be the need to pack our bags and stampede out of the Netherlands 
              post-haste.<br /><br />

              For those of you with an appetite for more, here is some interesting reading: <a href="https://www.iex.nl/Artikel/750580/Invoering-nieuwe-Box-3-in-2025-is-onhaalbaar.aspx" target="_nofollow">how the various policital parties view the new box 3 system</a> and
              what each party will be pushing for or against.
            </Typography>
            <Typography variant="h5" gutterBottom sx={{ mt:2, mb:2 }}>Coming Up</Typography>
            <Typography variant="body2" gutterBottom >
              Of course, as soon as we know more details about the new box 3 system, I will redo the case studies and update the 
              spreadsheet to reflect reality. I will also be writing about a tool that you can use to simulate the chances of 
              your portfolio surviving your retirement. Not only does this tool run simulations using historical returns, it is 
              also possible to simulate box 3 tax payments. If you want to be notified of these exciting events, head over to 
              the <a href="/contact">contact page</a> to sign up for the newsletter!
            </Typography>
        </Paper>
      </Container>
      <Footer/>
    </ThemeProvider>
  );
}
