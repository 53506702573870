import * as React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Markdown from './Markdown';
import { Helmet } from "react-helmet";

function ChangeHead(props) {
  if (props.upd) {
    return (
      <Helmet>
          <title>{props.title}</title>
          <meta name="description" content={props.desc} />
        </Helmet>
    );
  } else {
    return null;
  }
}

function RenderPreview(props) {
  const { post, to, noheadings, htype} = props;
  var previewpost;

  const strpost = String(post);

  const previewIndex = strpost.indexOf("[preview]");
  previewpost = strpost.substring(0, previewIndex);
 
  return (
    <>
      <Markdown className="markdown" noheadings={noheadings} htype={htype} key={post.substring(0, 40)}>
        {previewpost}
      </Markdown>
      <Typography>
        <a href={to} target='_blank' rel="noreferrer">Continue reading.....</a>
      </Typography>
    </>
  )
}

/*
 * Main renders two kinds of .md files:
 * 1. Post previews and 
 * 2. Full pages (blog posts linked from archives, pages like the glossary, tax calculator help)
 * 
 * For type '1':
 * An array of posts is passed into previews. This array includes the post text and a link to the actual
 * post. The link is used in the 'Continue reading...' portion of the preview. For previews changehead is
 * set to true because we don't have to update head. fullwidth is set to false because the preview posts 
 * render with a sidebar next to them. We also pass in a title to show before the post preview.
 * 
 * For type '2':
 * A single post is passed in via 'post'. We also have to update head, so changehead is set to true. fullwidth
 * is also true. Since changehead is true, the associated headtitle and head description (headdesc) must 
 * also be passed in. 
 * 
 * Both types of posts can make use of noheadings and htype. These variables are passed to the markdown
 * renderer to tell it which headings, if any, we want to generate links to.
 */
function Main(props) {
  const { previews, post, title, changehead, headtitle, headdesc, noheadings, htype, fullwidth} = props;

  if (fullwidth) {

    return (
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          '& .markdown': {
            py: 3,
          },
        }}
      >
        <ChangeHead upd={changehead} title={headtitle} desc={headdesc} />
        <Divider /> 
        <Markdown className="markdown" noheadings={noheadings} htype={htype} key={post.substring(0, 40)}>
          {post}
        </Markdown>
      </Grid>
    );
  }
  return (
    <Grid
      item
      xs={12}
      md={8}
      order={{ md: 1, xs: 2 }}
      sx={{
        '& .markdown': {
          py: 3,
        },
      }}
    >
      <ChangeHead upd={changehead} title={headtitle} desc={headdesc} />
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Divider />
      {previews.map(({post, to}) => (
        <RenderPreview className="markdown" post={post} to={to} noheadings={noheadings} htype={htype} key={post.substring(0, 40)}/>
      ))}
    </Grid>
  );
}

Main.propTypes = {
  post: PropTypes.string,
  title: PropTypes.string,
  changehead: PropTypes.bool.isRequired,
  noheadings: PropTypes.bool.isRequired,
  fullwidth: PropTypes.bool.isRequired,
};

export default Main;
