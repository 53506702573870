import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from './Header';
import Footer from './Footer';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter'; 
import { sections } from './Blog';
import { MailchimpForm } from './Sidebar';
import Box from '@mui/material/Box';
import { Helmet } from "react-helmet";
import Paper from '@mui/material/Paper';

const social = [
  { name: 'Twitter', link: 'https://twitter.com/theabnl', icon: TwitterIcon },
  { name: 'Instagram', link: 'https://www.instagram.com/theamericanburgerguide', icon: InstagramIcon },
];

const theme = createTheme();

export default function Contact() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg">
        <Helmet>
          <title>Contact Us</title>
          <meta name="description" content="How to get in touch" />
        </Helmet>
        <Header title="Contact Us" sections={sections} />
        <Paper
          sx={{
            position: 'relative',
            p: { xs: 2, md: 2 },
            mb: 2,
          }}
        >
        <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
          Email
        </Typography>
        <Typography variant="body2">
          Comments? Suggestions? Adoring fan mail? An idea for a new calculator? Send them my way at: <a href="mailto:anureita@theamericanburger.nl" target="_top">anureita@theamericanburger.nl</a>
        </Typography>
        <Typography variant="subtitle" color="inherit" noWrap>
          &nbsp;
        </Typography>
        <Typography variant="body2">
          If you have a suggestion for a new entry to the Store Finder section of this site, 
          send it to <a href="mailto:storefinder@theamericanburger.nl" target="_top">storefinder@theamericanburger.nl</a>.
        </Typography>
        <Typography variant="subtitle" color="inherit" noWrap>
          &nbsp;
        </Typography>
        <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
          Connect on Social Media
        </Typography>
        {social.map((network) => (
          <Link
            display="block"
            variant="body2"
            href={network.link}
            target="_blank"
            rel="noopener"
            key={network.name}
            sx={{ mb: 0.5 }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <network.icon />
              <span>{network.name}</span>
            </Stack>
        </Link>
        ))}
        <Typography variant="subtitle" color="inherit" noWrap>
          &nbsp;
        </Typography>
        <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
          Subscribe
        </Typography>
        <Typography variant="body2" gutterBottom sx={{ mb: 2 }}>
          To be notified when new content is added to the site, sign up here!
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            maxWidth: 600,
          }}
        >
          <MailchimpForm />
        </Box>  
        </Paper>
      </Container>
      <Footer/>
    </ThemeProvider>
  );
}
