import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from './Header';
import Footer from './Footer';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import { sections } from './Blog';
import { Helmet } from "react-helmet";
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Paper from '@mui/material/Paper';

const theme = createTheme();

const mid_consts = {
  income_phaseout: 73031, /* Income threshold above which deductions are capped */
  tax_rate_low: .3693, /* Maximum deduction % in 2023 */
  tax_rate_high: .495, /* Higher tax bracket, above €73031 */
  nrv_at_second_highest_bracket: 4200, /* See brackets for explanation */
};

class nrv_brackets {
  constructor(woz_start, woz_end, nrv_percent) {
    this.woz_start = woz_start;
    this.woz_end = woz_end;
    this.nrv_percent = nrv_percent;
  }
}

/* 
 * [WOZ start, WOZ end, nrv %]
 * NRV (eigenwoningforfait) % in 2023 based on WOZ value of home
 * Reference: https://www.belastingdienst.nl/wps/wcm/connect/nl/koopwoning/content/hoe-werkt-eigenwoningforfait
 * The last bracket is different - it is €4200 (the previous bracket) + 2.35% of the value above €1200000
 */
const brackets = [
  new nrv_brackets (0, 12500, 0),
  new nrv_brackets (12501, 25000, .001),
  new nrv_brackets (25001, 50000, .002),
  new nrv_brackets (50001, 75000, .0025),  
  new nrv_brackets (75001, 1200000, .0035),
  new nrv_brackets (1200001, 50000000, .0235),
];

function calcNetDeduction(woz_value, annual_interest, income) {

  var deduction = mid_consts['tax_rate_low'] * annual_interest;
  var nrv = 0;
  var nrv_tax = 0;

  for (var i = 0; i < brackets.length; i++) {
    if (woz_value > brackets[i].woz_end) {
      continue;
    }
    if (i === (brackets.length - 1)) {
      nrv = mid_consts['nrv_at_second_highest_bracket'] + (woz_value - brackets[i].woz_start - 1) * brackets[i].nrv_percent;
    } else {
      nrv = woz_value * brackets[i].nrv_percent;
    }
    break;
  }
  
  if (income >= mid_consts['income_phaseout']) {
    nrv_tax = nrv * mid_consts['tax_rate_high'];
  } else {
    nrv_tax = nrv * mid_consts['tax_rate_low'];
  }

  if (deduction > nrv_tax) {
    return["You get a refund!", deduction -  nrv_tax];
  } else if (deduction < nrv_tax) {
    return["You owe tax on your house", nrv_tax - deduction];
  } else {
    return["Your NRV tax is equal to your deduction", 0];
  }
}

export default function MortgageDeductionCalc() {

  let owe_or_refund = "";
  let amount = 0;

  const [woz_value, setWozValue] = React.useState(0);
  const handleWozValueChange = (event) => {
    setWozValue(event.target.value);
  };

  const [annual_interest, setAnnualInterest] = React.useState(0);
  const handleAnnualInterestChange = (event) => {
    setAnnualInterest(event.target.value);
  };

  const [income, setIncome] = React.useState(0);
  const handleIncomeChange = (event) => {
    setIncome(event.target.value);
  };

  const [result, setResult] = React.useState("Refund or tax owed:");
  const [amt, setAmt] = React.useState(0);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg">
        <Helmet>
          <title>Mortgage Interest Deduction in the Netherlands</title>
          <meta name="description" content="A calculator to compute how much of a refund you can expect from mortgage interest deduction in the Netherlands" />
        </Helmet>
        <Header title="The American Burger's Guide to Mortgage Interest Deduction" sections={sections} />
        <main>
          <Paper
            sx={{
              position: 'relative',
              p: { xs: 2, md: 4 },
              mb: 2,
            }}
          >
            <Grid container spacing={1}>
              <Grid item md={8}>
                <Typography variant="body2" paragraph>
                  When you take out a mortgage to buy your primary home in the Netherlands, the mortgage interest is
                  deductible (if you <a href="https://www.belastingdienst.nl/wps/wcm/connect/nl/koopwoning/content/hypotheekrente-aftrekken" target="_blank" rel="noreferrer">meet certain conditions</a>). However, 
                  mortgage interest deduction in the Netherlands doesn't work in quite the same way as
                  it does in the U.S.. This article describes how it does work, and the accompanying calculator allows you to
                  compute how much of a deduction (if any!) you can expect for your specific case.<br/><br />

                  <b>Understanding the *Eigenwoningforfait* or Notional Rental Value</b><br />

                  When you own your home in the Netherlands, then from an income tax perspective your home is assumed to 
                  generate taxable income. This taxable income is the notional rental value (*eigenwoningforfait* in Dutch).

                  The notional rental value (NRV) of your home is calculated as follows:<br /><br />

                  1. <a href="https://www.wozwaardeloket.nl/" target="_blank" rel="noreferrer">Look up the WOZ value</a> of your home.<br /> 
                  2. On the <a href="https://www.belastingdienst.nl/wps/wcm/connect/nl/koopwoning/content/hoe-werkt-eigenwoningforfait" target="_blank" rel="noreferrer">belastingdienst site look up the NRV %</a> that corresponds to the WOZ value of your home.<br />
                  3. Multiply the WOZ value by the NRV % to get the NRV.<br /><br />

                  The NRV is added to your box 1 income. In other words, the NRV increases your tax burden. <br />

                  The NRV is taxed at your <a href="/glossary#marginaltaxrate" target="_blank">marginal tax rate</a>.<br /><br />
                  
                  <b>Deducting your mortgage interest</b><br />
                  While the NRV increases your taxable income, your mortgage interest reduces your tax burden. As you can in the
                  U.S., you can deduct your mortgage interest from your taxable income.<br /><br />

                  <b>How much of your mortgage interest can you deduct?</b><br />
                  While the NRV is taxed at your <a href="/glossary#marginaltaxrate" target="_blank">marginal tax rate</a>, you may
                  not be able to deduct your mortgage interest at your <a href="/glossary#marginaltaxrate" target="_blank">marginal tax rate</a>, 
                  further reducing the effect of your morgage interest deduction.<br />

                  In 2023 the rate for mortgage income deduction has been capped at <b>36.93%</b>. This means that if you 
                  make <a href="https://www.belastingdienst.nl/wps/wcm/connect/nl/aftrek-en-kortingen/content/afbouw-tarief-aftrekposten-bij-hoog-inkomen" target="_blank" rel="noreferrer">more than €73,031</a> your 
                  marginal tax rate is 49.5%, but you cannot deduct your mortgage interest at that rate. Note that 36.93% happens to be
                  the box 1 income tax rate for income below €73,031.<br /><br />

                  So, if you make more than the income threshold, your NRV is taxed at your marginal rate of 49.5%, but your mortgage
                  interest can only be deducted at 36.93%.<br /><br />

                  <b>Will my mortgage interest deduction result in a tax refund?</b><br />
                  Your tax refund isn't simply the size of your mortgage interest deduction because of the tax owed on your NRV.<br />
                  If your mortgage interest deduction is more than the tax you owe on your NRV, you may (depending on the other factors in your tax return) get a 
                  refund from the belastingdienst.<br />
                  On the other hand, if the tax you owe on your NRV is greater than or equal to your mortgage interest deduction, you
                  will either get no refund, or even end up owing the belastingdienst when you file your taxes!<br /><br />

                  As the WOZ value of your house increases, your refund will smaller.<br />
                  As you pay off your mortgage and pay less interest over time, your refund will get smaller.<br /><br />

                  <b>Disclaimers</b><br />
                  I am not a tax professional, and this is my hobby project - not to be misconstrued as tax advice.

               </Typography>
              </Grid>
              <Grid item md={4}>
                <Grid container spacing={1} columns={{ xs: 6, md: 6 }}>
                  <Grid item xs={12} md={12} container sx={{alignItems: "center", justifyContent: "center"}}>
                    <Typography><b>The Calculator</b></Typography>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Accordion disableGutters> 
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="woz-value-explain"
                      >
                        <Typography variant="body2">WOZ value of your home</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography variant="body2">
                          The value of your home as assessed <a href="https://www.amsterdam.nl/en/municipal-taxes/property-valuation-woz/" target="_blank" rel="noreferrer">by the municipality</a>. 
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <TextField
                      id="woz-value"
                      type="number"
                      value={woz_value}
                      onChange={handleWozValueChange}
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">€</InputAdornment>,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Accordion disableGutters>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="annual-mortgage-interest-explain"
                        >
                          <Typography variant="body2">Annual mortgage interest</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography variant="body2">
                            The amount of mortgage interest your paid to your lender in one calendar year.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <TextField
                      id="interest"
                      type="number"
                      value={annual_interest}
                      onChange={handleAnnualInterestChange}
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">€</InputAdornment>,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Accordion disableGutters>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="year-explain"
                        >
                          <Typography variant="body2">Annual box 1 income</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography variant="body2">
                            The income covered by box 1 that you report to the belastingdienst. If you are employed this will be
                            the amount mentioned in your jaaropgave.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                  </Grid>
                  <Grid item xs={2} md={2}>
                    <TextField
                      id="income"
                      type="number"
                      value={income}
                      onChange={handleIncomeChange}
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">€</InputAdornment>,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} container sx={{alignItems: "center", justifyContent: "center"}}>
                    <Button 
                      variant="contained"
                      onClick={() => {
                        [owe_or_refund, amount] = calcNetDeduction(woz_value, annual_interest, income);
                        setAmt(amount);
                        setResult(owe_or_refund);
                      }}
                    >
                      Compute
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={12} container sx={{alignItems: "center", justifyContent: "center"}}>
                    <Typography><b>{result}:</b> €{amt.toFixed(2)}</Typography>
                  </Grid>
                </Grid>
                <Grid container>
          </Grid>
          </Grid>
          </Grid>
          </Paper>
        </main>
      </Container>
      <Footer/>
    </ThemeProvider>
  );
}