import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from './Header';
import MainFeaturedPost from './MainFeaturedPost';
import Footer from './Footer';
import { sections } from './Blog';
import { Helmet } from "react-helmet";

const imageAttribution = [
  {
    bylink: "https://unsplash.com/@randyfath?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText",
    by: "Randy Fath",
    from: "Unsplash",
    img_name: 'underconstruction.jpg',
  },
];

const mainFeaturedPost = {
  title: 'Coming Soon',
  description:
    "Content coming soon.",
  image: 'underconstruction.jpg',
  imageText: 'House under construction',
  linkText: '',
  linkTo: '',
};

const theme = createTheme();

export default function ComingSoon() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg">
        <Helmet>
          <title>Coming Soon</title>
          <meta name="description" content="Content under construction, coming soon!" />
        </Helmet>
        <Header title="Under Construction" sections={sections} />
        <main>
          <MainFeaturedPost post={mainFeaturedPost} />
        </main>
      </Container>
      <Footer imageattr={imageAttribution}/>
    </ThemeProvider>
  );
}
